// countrySlice.js
import { createSlice } from '@reduxjs/toolkit';

export const countrySlice = createSlice({
  name: 'country',
  initialState: {
    country: '',
    currency: '',
  }, 
  reducers: {
    setCountryAndCurrency: (state, action) => {
      state.country = action.payload.country;
      state.currency = action.payload.currency;
    },
  },

});

export const { setCountryAndCurrency } = countrySlice.actions;
export default countrySlice.reducer;