import { StateProps, scrollToTop } from "./ParentStates";
import Img from "../../../../assets/image-icon.png";
import { useState } from "react";
import LightbulbIcon from "@mui/icons-material/Lightbulb";

const State3 = ({
  activeState,
  setActiveState,
  userAdData,
  setUserAdData,
}: StateProps) => {
  const [images, setImages] = useState<(string | null)[]>(
    userAdData.images || Array(6).fill(null)
  );
  const [error, setError] = useState("");

  const handleImageChange = (index: any, event: any) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      const allowedTypes = ["image/png", "image/jpeg", "image/heic"];
      
      if (!allowedTypes.includes(fileType)) {
        setError("Only PNG, JPEG, and HEIC formats are allowed.");
        return;
      }

      setError(""); // Clear error if file type is valid
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result;
        const newImages = [...images];
        if (typeof result === "string") {
          newImages[index] = result;
          setImages(newImages);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = (index: any, event: any) => {
    event.preventDefault(); // Prevent default action
    event.stopPropagation(); // Stop event propagation

    const newImages = [...images];
    newImages[index] = null;
    setImages(newImages);
  };

  const handleLabelClick = (index: number, event: React.MouseEvent) => {
    // Check if the click target is not the remove icon
    if (
      event.target instanceof HTMLElement &&
      event.target.classList.contains("remove-icon")
    ) {
      event.preventDefault();
      return;
    }

    // Otherwise, trigger the file input click
    document.getElementById(`upload-image-${index}`)?.click();
  };

  const handleContinue = () => {
    const hasAtLeastOneImage = images.some((image) => image !== null);

    if (!hasAtLeastOneImage) {
      setError("At least one image must be uploaded");
      return;
    }

    setError("");
    setUserAdData((prevData) => ({
      ...prevData,
      images: images.filter((image) => image !== null),
    }));
    setActiveState(4);
    scrollToTop();

    // console.log({
    //   ...userAdData,
    //   images: images.filter((image) => image !== null),
    // });
  };

  return (
    <>
      <div className="row parent-top-banner-state3 m-2 m-md-0">
        <div className="head-row">
          <h5 className="head-title">Upload Photos</h5>
          <p>UPLOAD UP TO 6 PHOTOS</p>
        </div>
        <p className="head-para">
          For the cover picture we recommend using the landscape mode.
        </p>

        {error && <div className="col-12 text-danger">{error}</div>}

        <div className="image-upload-container">
          {images.map((image, index) => (
            <div className="image-box" key={index}>
              <label onClick={(e) => handleLabelClick(index, e)}>
                {image ? (
                  <div className="image-wrapper">
                    <img
                      src={image}
                      alt={`Upload ${index + 1}`}
                      className="uploaded-image"
                    />
                    <span
                      className="remove-icon"
                      onClick={(e) => handleRemoveImage(index, e)}
                    >
                      ×
                    </span>
                  </div>
                ) : (
                  <div className="placeholder-image">
                    <img src={Img} alt="" />
                  </div>
                )}
              </label>
              <input
                type="file"
                id={`upload-image-${index}`}
                onChange={(e) => handleImageChange(index, e)}
                style={{ display: "none" }}
              />
            </div>
          ))}
        </div>
      </div>

      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <p className="text-info">
            <LightbulbIcon />
          </p>
          <p>
            Post your ad on our platform and effortlessly edit or update it
            anytime
          </p>
        </div>

        <div className="button-parent">
          {/* <button
            className="btn btn-info"
            onClick={() => {
              setActiveState(2);
              scrollToTop();
            }}
          >
            Previous
          </button> */}
          <button
            className="btn btn-info"
            onClick={handleContinue}
            style={{ marginLeft: "10px" }}
          >
            CONTINUE
          </button>
        </div>
      </div>
    </>
  );
};

export default State3;