import adImage from "../../../assets/Samsung-Galaxy-S22-Ultra-Image.jpg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PhoneIcon from "@mui/icons-material/Phone";
import ChatIcon from "@mui/icons-material/Chat";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SearchIcon from "@mui/icons-material/Search";

const Ads = () => {
  return (
    <div>
      {/* Ad Filter Tabs */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="ad-filters my-3">
          <button className="btn btn-outline-info active">View All (1)</button>
          <button className="btn btn-outline-info">Active Ads (1)</button>
          <button className="btn btn-outline-info">Inactive Ads (0)</button>
          <button className="btn btn-outline-info">Pending Ads (0)</button>
          <button className="btn btn-outline-info">Expired Ads (0)</button>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="search"
            name=""
            id=""
            placeholder="Search By Ad Title..."
            className="form-control"
            style={{
              margin: "0px 5px",
              padding: "1px 5px",
              width: "180px",
              height: "30px",
              borderRadius: "7px",
            }}
          />
          <button
            className="btn btn-info"
            style={{ padding: "0px 3px", borderRadius: "7px" }}
          >
            <SearchIcon />
          </button>
        </div>
      </div>

      {/* Ad Item */}
      <div className="ad-item p-3 my-2 d-flex align-items-center">
        <div className="ad-image">
          <img src={adImage} alt="Ad Image" className="img-fluid rounded" />
        </div>
        <div className="ad-details flex-grow-1 mx-3">
          <h5 className="ad-title">
            Best Quality Mobile Phone in town iPhone 14 Plus{" "}
            <span className="ad-category">- in Mobile Phones</span>
          </h5>
          <p className="ad-price">Rs 111500</p>
          <p className="ad-status">
            Active From 3 Sep To 3 Oct{" "}
            <span className="badge bg-success">Active</span>
          </p>
          <div className="ad-stats" style={{ display: "flex", gap: "30px" }}>
            <div className="stat-item">
              <VisibilityIcon style={{ fontSize: "20px" }} /> 2 Views
            </div>
            <div className="stat-item">
              <PhoneIcon style={{ fontSize: "20px" }} /> 0 Tel
            </div>
            <div className="stat-item">
              <ChatIcon style={{ fontSize: "20px" }} /> 0 Chats
            </div>
            <div className="stat-item">
              <FavoriteIcon style={{ fontSize: "20px" }} /> 0 Likes
            </div>
          </div>
        </div>
        {/* Ad Actions */}
        <div className="ad-actions">
          <button className="btn btn-outline-info mx-2">Edit</button>
          <button className="btn btn-info">Sell Faster Now</button>
        </div>
      </div>
    </div>
  );
};

export default Ads;
