import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import BlogPost from "./BlogPost";
import { useParams } from "react-router-dom";

import { BlogDataInterface } from '../utils';

const Index = () => {
  const { blogTitle } = useParams();
  const [blogPost, setBlogPost] = useState<BlogDataInterface | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(
          "https://us-central1-ibad-mobile.cloudfunctions.net/api/blogs"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const blogs = await response.json();
        const matchedBlog = blogs.find(
          (blog: BlogDataInterface) => blog.title === blogTitle
        );
        if (matchedBlog) {
          setBlogPost(matchedBlog);
        } else {
          setError("Blog post not found.");
        }
      } catch (e) {
        console.error("Failed to fetch blogs:", e);
        setError("Failed to fetch blogs.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlogs();
  }, [blogTitle]);

  if (isLoading) {
    return (
      <div className="container my-5">
        <p className="text-center">Loading Blog Post...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container my-5">
        <p className="text-center">{error}</p>
      </div>
    );
  }

  return (
    <div className="container my-5">
      <Helmet>
        <title>
          {blogPost
            ? `${blogPost.title} - Ibad Mobiles`
            : "Blog Post - Ibad Mobiles"}
        </title>
      </Helmet>
      {blogPost ? (
        <BlogPost blogPost={blogPost} />
      ) : (
        <p>Blog post not found.</p>
      )}
    </div>
  );
};

export default Index;