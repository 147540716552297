import React, { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";

const ContactForm = () => {
  const [state, handleSubmit] = useForm("xpzvgqqy");

  if (state.succeeded) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="card">
          <div className="card-header">
            {/* <p>Your response have been sent.</p> */}
          </div>
          <div className="card-body">
            <h5>Thank You!</h5>
            <p>
              Your message has been sent successfully. We will get back to you
              as soon as possible.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="card">
        <div className="card-header">
          <h5>Send a Message</h5>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Full Name"
                required
              />
              <ValidationError
                prefix="Name"
                field="name"
                errors={state.errors}
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="Your email"
                required
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="number"
                name="number"
                placeholder="Your Number"
                required
              />
              <ValidationError
                prefix="Number"
                field="number"
                errors={state.errors}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="subject"
                name="subject"
                placeholder="Enter subject"
                required
              />
              <ValidationError
                prefix="Subject"
                field="subject"
                errors={state.errors}
              />
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                id="message"
                name="message"
                rows={5}
                placeholder="Enter your message here..."
                required
              ></textarea>
              <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
              />
            </div>
            <div
              className="form-group text-left"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <button
                type="submit"
                className="btn btn-info"
                disabled={state.submitting}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;

// import React from "react";

// const ContactForm = () => {
//   return (
//     <div style={{ display: "flex", justifyContent: "center" }}>
//       <div className="card">
//         <div className="card-header">
//           <h5>Send a Message</h5>
//         </div>
//         <div className="card-body">
//           <form>
//             <div className="form-group">
//               {/* <label htmlFor="name">Name</label> */}
//               <input
//                 type="text"
//                 className="form-control"
//                 id="name"
//                 placeholder="Full Name"
//               />
//             </div>
//             <div className="form-group">
//               {/* <label htmlFor="email">Email address</label> */}
//               <input
//                 type="email"
//                 className="form-control"
//                 id="email"
//                 placeholder="Your email"
//               />
//             </div>
//             <div className="form-group">
//               {/* <label htmlFor="number">Number</label> */}
//               <input
//                 type="text"
//                 className="form-control"
//                 id="number"
//                 placeholder="Your Number"
//               />
//             </div>
//             <div className="form-group">
//               {/* <label htmlFor="subject">Subject</label> */}
//               <input
//                 type="text"
//                 className="form-control"
//                 id="subject"
//                 placeholder="Enter subject"
//               />
//             </div>
//             <div className="form-group">
//               {/* <label htmlFor="message">Message</label> */}
//               <textarea
//                 className="form-control"
//                 id="message"
//                 rows={5}
//                 placeholder="Enter your message here..."
//               ></textarea>
//             </div>
//             <div className="form-group text-left">
//               <button type="submit" className="btn btn-info">
//                 Submit
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ContactForm;
