import React from "react";
import { Helmet } from "react-helmet";
import PrivacyPolicy from "./PrivacyPolicy";

const index = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Ibad Mobiles</title>
        <meta
          name="description"
          content="Read Ibad Mobiles' Privacy Policy to understand how we collect, use, and protect your information when you visit our website or use our services."
        />
        <meta
          name="keywords"
          content="privacy policy, personal data, Ibad Mobiles privacy, information security"
        />
        <meta name="google-adsense-account" content="ca-pub-3818311597459709"></meta>
      </Helmet>
      <PrivacyPolicy/>
    </>
  );
};

export default index;
