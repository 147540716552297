import { Helmet } from "react-helmet";
import BlogsTopSection from "./BlogsTopSection";
import Breadcrumb from "./BreadcrumbBlogs";
import BlogsBottomSection from "./BlogsBottomSection";
import { useEffect, useState } from "react";
import "./blogs.scss";
import ScreenLoader from "../../../Component/ScreenLoader/ScreenLoader";

const Index = () => {
  const [blogsData, setBlogsData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(
          "https://us-central1-ibad-mobile.cloudfunctions.net/api/blogs"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blogs = await response.json();
        setBlogsData(blogs);
        setIsLoading(false);

        console.log("Blogs");
        console.log(blogs);
      } catch (error) {
        console.error("Failed to fetch blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  if (isLoading === true) {
    return (
      <div className="container my-5">
        {/* <p className="text-center">Loading Blogs...</p> */}
        <ScreenLoader />

      </div>
    );
  }

  return (
    <div className="container my-5 blogs-parent-div">
      <Helmet>
        <title>Mobile Tech News: Ibad Mobile Blog - Explore the Latest</title>
        <meta
          name="description"
          content="Unbiased mobile tech news, insightful blogs & trending topics. Stay ahead with Ibad Mobile's expert coverage. Read Now!"
        />
        <meta
          name="keywords"
          content="Mobile tech news"
        />
      </Helmet>
      <Breadcrumb />
      <BlogsTopSection blogsData={blogsData} />
      <BlogsBottomSection blogsData={blogsData} />
    </div>
  );
};

export default Index;
