import { Link } from "react-router-dom";

const Breadcrumb = () => {
  return (
    <div className="row mb-4 mt-5">
      <div className="col">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li
              className="breadcrumb-item"
              style={{ fontSize: "12px", fontWeight: "300" }}
            >
              <Link className="text-dark" to="/">
                Home
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-info"
              aria-current="page"
              style={{ fontSize: "12px", fontWeight: "400" }}
            >
              Videos
            </li>
          </ol>
        </nav>
        <h3
          className="mt-2"
          style={{
            fontFamily: "Roboto",
            fontWeight: "800",
            fontSize: "24px",
          }}
        >
          Our Latest Videos
        </h3>
        <p
          className="mt-3"
          style={{
            fontFamily: "Roboto",
            fontWeight: "300",
            fontSize: "16px",
          }}
        >
          Experience the future of mobile purchasing through vivid visuals and
          expert insights
        </p>
      </div>
    </div>
  );
};

export default Breadcrumb;
