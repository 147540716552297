import { Link } from "react-router-dom";
import slugs from "../../../config/slugConfig";

const Breadcrumb = () => {
  return (
    <div className="row mb-4 mt-5">
      <div className="col">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li
              className="breadcrumb-item"
              style={{ fontSize: "12px", fontWeight: "300" }}
            >
              <Link className="text-dark" to="/">
                Home
              </Link>
            </li>
            <li
              className="breadcrumb-item active"
              // aria-current="page"
              style={{
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              <p
                // to={`/${slugs.comparison}`}
                className="text-info"
                style={{
                  display: "inline",
                }}
              >
                Contact Us
              </p>
            </li>
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default Breadcrumb;
