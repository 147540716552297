import React, { useContext, useEffect, useRef, useState } from "react";
import "./navbar.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { AuthContext } from "../../context/AuthContext";
import { useAuth } from "../../context/AuthContext";
import { signOut } from "firebase/auth";
import { auth, db } from "../../config/firebase";
import logo from "../../assets/ibadmobilemainlogowithoutname.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DehazeIcon from "@mui/icons-material/Dehaze";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setCountryAndCurrency } from "../../store/slice/countrySlice";
import DataLoader, { addpriceInCurrency } from "../../DataLoader";
import { setMobileData } from "../../store/slice/mobilesSlice";
import slugs from "./../../../src/config/slugConfig";
import Profile_icon_placeholder from "../../assets/demo_profile_icon.png";
import { doc, getDoc } from "firebase/firestore";

const currencyMapping: { [key: string]: string } = {
  IN: "₹",
  PK: "RS",
  US: "$",
  UAE: "AED",
  // DE: "€",
  EUR: "€",
  GB: "£",
  AU: "AUD",
  CA: "CAD",
};

const Navbar: React.FC = () => {
  // const { isAuthenticated, dispatch }: any = useContext(AuthContext);
  const reduxDispatch = useDispatch();
  const dropdownRef = useRef<HTMLDivElement | null>(null); // Add this line

  // const [country, setCountry] = useState('');
  const country = useSelector((state: RootState) => state.country.country); // Replace with the correct state slice if needed
  const [flagUrl, setFlagUrl] = useState("");
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(() => {
    const storedTab = sessionStorage.getItem("selectedTab"); // Changed to sessionStorage
    return storedTab !== null ? storedTab : slugs.home;
  });
  const [isLeftTabActive, setIsLeftTabActive] = useState(
    selectedTab === slugs.home
  );
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const navigate = useNavigate();
  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };
  const { user, isAuthenticated, logout } = useAuth();
  // console.log("----");
  // console.log("user");
  // console.log(user);

  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName);
    sessionStorage.setItem("selectedTab", tabName);
    setIsLeftTabActive(tabName === slugs.home);
    if (window.innerWidth < 768) {
      handleNavCollapse();
    }
    if (tabName.toLowerCase() == slugs.new_mobiles) {
      navigate(`${slugs.new_mobiles}?reset=reset`);
    } else {
      navigate(tabName);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleIpadTabletsClick = () => {
    const tabletsDropdown = document.querySelector(".tablets-dropdown");

    if (tabletsDropdown) {
      tabletsDropdown.classList.toggle("active");
    }
    // handleTabClick('Ipad/Tablets');
  };

  const flagMapping: { [key: string]: string } = {
    IN: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQi8FZpF3wHktnQGGD_0iyJx-_UHL6TVqgjZIDBcFu4d5VAzmc&s",
    PK: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDeX7bGLeSLsjtP25_5JigfY4E96UAuQXFFVlcuL7Vv9GYRpkc&s",
    US: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6phS_XDws3MudmjgFh9L_9gkUuCka0RzHTGrKZFkRNJSGzzXJ&s",
    UAE: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRE1sUR-D_jHN5Hn3mOXN10d0Bm83ORXfsFvdxvEH0bD_K6XU9I&s",
    EUR: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9oHfoAkG0MZFQfc4JGV3VlAX4aGiApWVmmpATKtsTDMjLGEQ9&s",
    GB: "https://upload.wikimedia.org/wikipedia/en/archive/a/ae/20190917170935%21Flag_of_the_United_Kingdom.svg",
    // DE: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9oHfoAkG0MZFQfc4JGV3VlAX4aGiApWVmmpATKtsTDMjLGEQ9&s",
    AU: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Flag_of_Australia.svg",
    CA: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Canada.svg",
  };
  useEffect(() => {
    if (flagMapping[country]) {
      setFlagUrl(flagMapping[country]);
    }
  }, [country]);

  const [showDropdown, setShowDropdown] = useState(false);

  const countries = [
    { name: "India", code: "IN" },
    { name: "Pakistan", code: "PK" },
    { name: "United States", code: "US" },
    { name: "United Arab Emirates", code: "UAE" },
    { name: "Germany", code: "EUR" },
    { name: "Pounds", code: "GB" },
    { name: "Australia", code: "AU" },
    { name: "Canada", code: "CA" },
    // { name: "Germany", code: "DE" },
    // other countries...
  ];
  const mobiles = useSelector((state: RootState) => state.mobiles.mobiles); // Replace with your state slice

  const handleCountrySelect = (selectedCountry: any) => {
    const currency = currencyMapping[selectedCountry.code];
    if (currency) {
      localStorage.setItem("country", selectedCountry.code);
      localStorage.setItem("currency", currency);

      reduxDispatch(
        setCountryAndCurrency({ country: selectedCountry.code, currency })
      );
      const updatedMobiles = addpriceInCurrency(mobiles, currency);
      reduxDispatch(setMobileData(updatedMobiles));
      setShowDropdown(false);
    } else {
      console.error("Currency not found for the selected country");
    }
  };

  const countryx = useSelector((state: RootState) => state.country.country);

  useEffect(() => {
    // Extract the current route name from the pathname
    const currentRoute = location.pathname.split("/")[1]; // Assuming your route looks like '/Home', '/Mobiles', etc.

    // Update the selectedTab state based on the current route
    const currentTab = currentRoute || slugs.home; // Default to 'Home' if currentRoute is empty
    setSelectedTab(currentTab);
    sessionStorage.setItem("selectedTab", currentTab);
    // Rest of your useEffect code...
  }, [location.pathname]);

  // const handleLogout = () => {
  //   signOut(auth)
  //     .then(() => {
  //       dispatch({ type: "LOGOUT" });
  //       navigate("/auth/login");
  //       window.toastify("Logout Successfully", "success");
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       window.toastify("Something error", "error");
  //     });
  // };

  const [imageSrc, setImageSrc] = useState(Profile_icon_placeholder);

  // useEffect(() => {
  //   if (user?.photoURL) {
  //     setImageSrc(user.photoURL);
  //   }
  // }, [user]);

  useEffect(() => {
    const fetchUserProfileImage = async () => {
      if (user?.photoURL) {
        // If the user has a photoURL in the auth object, use it
        setImageSrc(user.photoURL);
      } else if (user?.uid) {
        // If no photoURL in auth, fetch the image from Firestore using UID
        try {
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            if (userData.image) {
              setImageSrc(userData.image); // Set image from Firestore if it exists
            }
          }
        } catch (error) {
          console.error("Error fetching user image:", error);
        }
      }
    };

    fetchUserProfileImage();
  }, [user]);

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-light"
      ref={dropdownRef}
    >
      <div className="container">
        <Link className="navbar-brand text-white" to="/">
          <img src={logo} alt="" width="50px" />
        </Link>
        <div className="first__flag position-relative">
          <div className="inner__flag  ">
            {flagUrl && <img src={flagUrl} alt="flag" width="30px" />}
            <span
              className="text-white ms-1"
              style={{
                fontSize: "14px",
                fontWeight: "300",
                fontFamily: "Roboto",
              }}
            >
              {country}
            </span>
            <KeyboardArrowDownIcon
              className="text-white ms-1"
              style={{ cursor: "pointer" }}
              onClick={() => setShowDropdown(!showDropdown)}
            />
            {showDropdown && (
              <div className="country-dropdown">
                {countries.map((country) => (
                  <div
                    key={country.code}
                    onClick={() => handleCountrySelect(country)}
                    className="country-item"
                  >
                    {flagUrl && (
                      <img
                        src={flagMapping[country.code]}
                        alt="flag"
                        width="25px"
                        className="shadow-lg"
                      />
                    )}
                    <span style={{ paddingLeft: "14px" }}>{country.code}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <button
          className={`navbar-toggler  text-white border-0 ${
            isNavCollapsed ? "" : "collapsed"
          }`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded={!isNavCollapsed}
          aria-label="Toggle navigation"
          onClick={handleNavCollapse}
        >
          <DehazeIcon className="text-white " />
        </button>
        <div
          className={`collapse navbar-collapse ${isNavCollapsed ? "" : "show"}`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mx-auto  mb-lg-0 text-center">
            <li
              className={`nav-item text-white ${
                selectedTab.toLowerCase() === slugs.home ? "active" : ""
              } taeb `}
              onClick={() => handleTabClick(slugs.home)}
            >
              <Link
                className={`nav-link  text-white mx-lg-2 ${
                  selectedTab === slugs.home ||
                  selectedTab === "sign-in" ||
                  selectedTab === "sign-up"
                    ? "active"
                    : ""
                }`}
                to="/"
                style={{ fontFamily: "Roboto", fontSize: "14px" }}
              >
                Home
              </Link>
            </li>
            {/* <li className='nav-item my-2 mx-lg-3'><Link to="/used-mobile" style={{ fontFamily: 'Roboto',fontSize:"14px" }}>Used Mobiles</Link>
            </li> */}
            <li
              className={`nav-item text-white mx-2 ${
                selectedTab.toLowerCase().includes("mobile-details") ||
                selectedTab.toLowerCase() == slugs.new_mobiles
                  ? "active"
                  : ""
              }`}
              onClick={() => handleTabClick("Mobiles")}
            >
              <span
                className={`nav-link  text-white mx-lg-2 ${
                  selectedTab === "New Mobiles" ? "active" : ""
                }`}
                style={{ fontFamily: "Roboto", fontSize: "14px" }}
              >
                Mobiles
              </span>
            </li>
            <li
              className={`nav-item text-white mx-2 ${
                selectedTab.toLowerCase() === slugs.comparison ? "active" : ""
              }`}
              onClick={() => handleTabClick(slugs.comparison)}
            >
              <span
                className={`nav-link  text-white mx-lg-2 ${
                  selectedTab === slugs.comparison ? "active" : ""
                }`}
                style={{ fontFamily: "Roboto", fontSize: "14px" }}
              >
                Compare
              </span>
            </li>
            {/* <li className={`nav-item text-white mx-2 ${selectedTab === 'Ipad/Tablets' ? 'active' : ''}`} onClick={handleIpadTabletsClick} >
              <a className={`nav-link  text-white mx-lg-2 ${selectedTab === 'Ipad/Tablets' ? 'active' : ''}`} style={{ fontFamily: 'Roboto', fontSize: "14px" }}>Ipad/Tablets</a>
              <ul className="tablets-dropdown">
                <li className={`nav-item text-white ${selectedTab === 'Samsung' ? 'active' : ''}`} onClick={() => handleTabClick('Samsung')}>
                  <a className={`nav-link  text-white ${selectedTab === 'Samsung' ? 'active' : ''}`} style={{ fontFamily: 'Roboto', fontSize: "14px" }}>Samsung</a>
                </li>
                <li className={`nav-item text-white ${selectedTab === 'Apple' ? 'active' : ''}`} onClick={() => handleTabClick('Apple')}>
                  <a className={`nav-link  text-white ${selectedTab === 'Apple' ? 'active' : ''}`} style={{ fontFamily: 'Roboto', fontSize: "14px" }}>Apple</a>
                </li>
                <li className={`nav-item text-white ${selectedTab === 'Oppo' ? 'active' : ''}`} onClick={() => handleTabClick('Oppo')}>
                  <a className={`nav-link  text-white ${selectedTab === 'Oppo' ? 'active' : ''}`} style={{ fontFamily: 'Roboto', fontSize: "14px" }}>Oppo</a>
                </li>
              </ul>
            </li> */}

            <li
              className={`nav-item text-white mx-2 ${
                selectedTab === slugs.blogs ? "active" : ""
              }`}
              onClick={() => handleTabClick(slugs.blogs)}
            >
              <a
                className={`nav-link  text-white mx-lg-2 ${
                  selectedTab === "Ipad/Tablets" ? "active" : ""
                }`}
                style={{ fontFamily: "Roboto", fontSize: "14px" }}
              >
                Blogs
              </a>
            </li>
            <li
              className={`nav-item text-white mx-2 ${
                selectedTab === slugs.videos ? "active" : ""
              }`}
              onClick={() => handleTabClick(slugs.videos)}
            >
              <a
                className={`nav-link  text-white mx-lg-2 ${
                  selectedTab === "Ipad/Tablets" ? "active" : ""
                }`}
                style={{ fontFamily: "Roboto", fontSize: "14px" }}
              >
                Videos
              </a>
            </li>
            <li
              className={`nav-item text-white mx-2 ${
                selectedTab.toLowerCase() === "aboutus" ? "active" : ""
              }`}
              onClick={() => handleTabClick("aboutus")}
            >
              <a
                className={`nav-link  text-white mx-lg-3 ${
                  selectedTab === "aboutus" ? "active" : ""
                }`}
                style={{ fontFamily: "Roboto", fontSize: "14px" }}
              >
                About Us
              </a>
            </li>
            {/* <div
              className="d-block d-md-none"
              style={{ display: "flex", alignItems: "center" }}
            >
              {isSignedIn ? (
                <button
                  className="text-white"
                  onClick={logout}
                  style={{
                    background: "#7B2CBF",
                    border: "none",
                    marginRight: "30px",
                    padding: "8px 12px",
                    borderRadius: "7px",
                  }}
                >
                  <a
                    className="nav-link text-white"
                    style={{ fontFamily: "Roboto", fontSize: "14px" }}
                  >
                    LOGOUT
                  </a>
                </button>
              ) : (
                <>
                  <button
                    className={` text-white mx-2`}
                    onClick={() => handleTabClick("sign-up")}
                    style={{ background: "none", border: "none" }}
                  >
                    <a
                      className={`nav-link  text-white `}
                      style={{ fontFamily: "Roboto", fontSize: "14px" }}
                    >
                      SIGN UP
                    </a>
                  </button>

                  <button
                    className={` text-white`}
                    onClick={() => handleTabClick("sign-in")}
                    style={{
                      background: "#7B2CBF",
                      border: "none",
                      marginRight: "30px",
                      padding: "8px 12px",
                      borderRadius: "7px",
                    }}
                  >
                    <a
                      className={`nav-link  text-white`}
                      style={{ fontFamily: "Roboto", fontSize: "14px" }}
                    >
                      SIGN IN
                    </a>
                  </button>
                </>
              )}
            </div> */}
            {/* <li
              className={`nav-item text-white mx-2 ${
                selectedTab.toLowerCase() === "privacy-policy" ? "active" : ""
              }`}
              onClick={() => handleTabClick("privacy-policy")}
            >
              <a
                className={`nav-link  text-white mx-lg-3 ${
                  selectedTab === "privacy-policy" ? "active" : ""
                }`}
                style={{ fontFamily: "Roboto", fontSize: "14px" }}
              >
                Privacy Policy
              </a>
            </li> */}
          </ul>
          {/* <Link to='/auth/register' className="btn text-white me-2" type="submit" style={{ fontFamily: 'Roboto', fontWeight: '400', fontSize: "14px" }}>SIGN UP</Link>
          {
            !isAuthenticated ?
              <Link to='/auth/login' className="btn btn-outline-primary" style={{ fontFamily: 'Roboto', fontWeight: '400', fontSize: "14px" }} >SIGN IN</Link>
              :
              <button className="btn btn-primary" onClick={handleLogout} style={{ fontFamily: 'Roboto', fontWeight: '400', fontSize: "14px" }}>LOGOUT</button>
          } */}

          <div
            className="second__flag  ms-2 position-relative rev-dir"
            style={{ display: "flex", alignItems: "center" }}
          >
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              {isAuthenticated ? (
                <div style={{ display: "flex", gap: "20px" }}>
                  {user && (
                    <div className="nav-item">
                      <img
                        src={imageSrc}
                        alt="profile"
                        className="nav-profile-pic"
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "100%",
                        }}
                      />
                    </div>
                  )}

                  <button
                    className="text-white"
                    onClick={() => {
                      logout();
                      navigate("/");
                    }}
                    style={{
                      background: "#7B2CBF",
                      border: "none",
                      marginRight: "30px",
                      padding: "8px 12px",
                      borderRadius: "7px",
                    }}
                  >
                    <a
                      className="nav-link text-white"
                      style={{ fontFamily: "Roboto", fontSize: "14px" }}
                    >
                      LOGOUT
                    </a>
                  </button>
                </div>
              ) : (
                <>
                  <button
                    className={` text-white mx-2`}
                    onClick={() => handleTabClick("sign-up")}
                    style={{ background: "none", border: "none" }}
                  >
                    <a
                      className={`nav-link  text-white `}
                      style={{ fontFamily: "Roboto", fontSize: "14px" }}
                    >
                      SIGN UP
                    </a>
                  </button>

                  <button
                    className={` text-white`}
                    onClick={() => handleTabClick("sign-in")}
                    style={{
                      background: "#7B2CBF",
                      border: "none",
                      marginRight: "30px",
                      padding: "8px 12px",
                      borderRadius: "7px",
                    }}
                  >
                    <a
                      className={`nav-link  text-white`}
                      style={{ fontFamily: "Roboto", fontSize: "14px" }}
                    >
                      SIGN IN
                    </a>
                  </button>
                </>
              )}
            </div> */}
            <div className="second-flag-display">
              {flagUrl && <img src={flagUrl} alt="flag" width="25px" />}
              <span
                className="text-white ms-1"
                style={{
                  fontSize: "16px",
                  fontWeight: "300",
                  fontFamily: "Roboto",
                }}
              >
                {country}
              </span>
              <KeyboardArrowDownIcon
                className="text-white ms-1"
                style={{ cursor: "pointer" }}
                onClick={() => setShowDropdown(!showDropdown)}
              />
            </div>
            {showDropdown && (
              <div className="country-dropdown">
                {countries.map((country) => (
                  <div
                    key={country.code}
                    onClick={() => handleCountrySelect(country)}
                    className="country-item"
                  >
                    {flagUrl && (
                      <img
                        src={flagMapping[country.code]}
                        alt="flag"
                        width="25px"
                        className="shadow-lg"
                      />
                    )}
                    <span style={{ paddingLeft: "14px" }}>{country.code}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
