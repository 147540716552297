import React from "react";
import MobileDetails from "./MobileDetails";

const index: React.FC = () => {
  return (
    <div className="new-mobile-detail-parent-class">
      <MobileDetails />
    </div>
  );
};

export default index;
