import React, { useContext, useState, ChangeEvent, FormEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './register.scss';
import { FacebookAuthProvider, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db, fbProvider } from '../../../config/firebase';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { AuthContext } from '../../../context/AuthContext';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

interface RegisterState {
  email: string;
  password: string;
  name: string;
}

const initialState: RegisterState = { email: '', password: '', name: '' };

const Register: React.FC = () => {
  const { dispatch }: any = useContext(AuthContext);
  const [state, setState] = useState<RegisterState>(initialState);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isGoogleLogin, setIsGoogleLogin] = useState(false);
  const [isFbLogin, setIsFbLogin] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState(s => ({ ...s, [name]: value }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, password } = state;
    setIsProcessing(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        addDocument(user);
        dispatch({ type: 'LOGIN' })
        // console.log('user is sign up');
        window.toastify('User is sign up successfully', 'success');
      })
      .catch((err) => {
        console.log(err.message);
        window.toastify('Something got error while login', 'error');
        setIsProcessing(false);
      });
  };

  const addDocument = async (user: any) => {
    const { name } = state;

    try {
      await setDoc(doc(db, 'users', user.uid), {
        fullName: name,
        email: user.email,
        uid: user.uid,
        role: 'user',
        dateCreated: serverTimestamp(),
      });

      // console.log('doc created');
      //   dispatch({ type: 'LOGIN', payload: { user } });
    } catch (error) {
      console.log(error);
    }

    setIsProcessing(false);
  };

  const handleGoogleSignIn = async () => {
    setIsGoogleLogin(true)
    const googleProvider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      addDocument(user);
      dispatch({ type: 'LOGIN' });
      console.log('user is signed up with Google');
      window.toastify('User is signed up successfully', 'success');
      setIsGoogleLogin(false)
    } catch (err) {
      console.error(err);
      window.toastify('Something went wrong while signing up', 'error');
      setIsGoogleLogin(false)
    }
  };
  const handleFacebookSignIn = () => {
    setIsFbLogin(true)
    signInWithPopup(auth, fbProvider)
      .then((result) => {
        const user = result.user;
        dispatch({ type: 'LOGIN', payload: user })
        window.toastify('facebook registered sign in successfully', 'success')
        navigate('/')
        const credential=FacebookAuthProvider.credentialFromResult(result);
        setIsFbLogin(false)
      })
      .catch((err) => {
        console.error(err)
        window.toastify('Something went wrong', 'error')
        setIsFbLogin(false)
      })
  }

return (
  <>
    <div className="container-fluid pt-3" style={{ position: 'relative', overflow: 'hidden' }}  >
      <div className="container">
        <div className="row py-5 px-2  ">
          <div className="col-12 col-lg-6 registerBack shadow-sm d-flex flex-column justify-content-center align-items-center text-white "></div>
          <div className="col-12 col-lg-6 bg-light shadow-sm">
            <div className="row pt-5 px-2 px-md-5 ">
              <div className="col-12 text-center ">

                <h1 className='' style={{ fontFamily: 'fantasy' }}>Register</h1>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row py-5">
                  <div className="col-12">
                    <input type="name" placeholder='Username' className='form-control  py-2 mb-4' name='name' onChange={handleChange} />
                  </div>
                  <div className="col-12">

                    <input type="email" placeholder='Email' className='form-control  py-2' name='email' onChange={handleChange} />
                  </div>
                  <div className="col-12">

                    <input type="password" placeholder='Password' className='form-control  py-2 mt-4' name='password' onChange={handleChange} />
                  </div>
                  <div className="col-12 mt-3 text-center ">
                    {/* <Link className='text-dark'>Forgot Password?</Link> */}
                  </div>
                  <div className="col-12 mt-4 text-center ">
                    <button className=" zoom-button  py-md-3 px-5 bg-danger fw-semibold  " disabled={isProcessing} style={{ boxShadow: "0 0 25px rgb(255, 168, 168)" }}>
                      {!isProcessing
                        ? <span>Register</span>
                        : <div className="spinner spinner-grow spinner-grow-sm"></div>
                      }
                    </button>
                  </div>
                  <div className="col-5 mt-4">
                    <hr />

                  </div>
                  <div className="col-2 text-center text-secondary mt-4">
                    <p>OR</p>

                  </div>
                  <div className="col-5 mt-4">
                    <hr />

                  </div>
                  <div className="col-12">

                    <button className='  btn w-100 rounded-1 py-2 facebook d-inline-block my-2 fw-semibold ' disabled={isFbLogin} onClick={handleFacebookSignIn}>
                      {
                        !isFbLogin ?
                          'Continue With Facebook' :
                          <div className="spinner-border spinner-border-sm"></div>
                      }
                    </button>
                  </div>
                  <div className="col-12">

                    <button className='  btn w-100 rounded-1  d-inline-block google py-2  fw-semibold  ' disabled={isGoogleLogin} onClick={handleGoogleSignIn}>{
                      !isGoogleLogin ?
                        'Continue With Google' :
                        <div className="spinner-border spinner-border-sm"></div>
                    }</button>
                  </div>

                  <p className='text-center text-secondary pt-3'>Already Have An Account?<Link to="/auth/login" className='text-dark'>Click here</Link></p>
                </div>
              </form>
            </div>
          </div>
        </div>

      </div>
    </div>

  </>
);
}
export default Register;