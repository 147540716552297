import "./mobileDetails.scss";
import React, { useState, useEffect } from "react";
import ShareIcon from "@mui/icons-material/Share";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import StarIcon from "@mui/icons-material/Star";
import ImageGallery from "react-image-gallery";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { MobileData } from "../NewMobiles/interface";
import axios from "axios";
import loader from "../../../assets/Screenloader.svg";
import arrow from "../../../assets/Exchange@2x.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { Helmet } from "react-helmet";
import { Rating } from "react-simple-star-rating";
import slugs from "./../../../../src/config/slugConfig";

const MobileDetails: React.FC = () => {
  const { id, modelName } = useParams<{ id: string; modelName: string }>();
  const [documents, setDocuments] = useState<MobileData[]>([]);
  const [selectedStorage, setSelectedStorage] = useState<number | 0>(0);
  const [isLoading, setIsLoading] = useState(true);
  const country = useSelector((state: RootState) => state.country.country); // Access mobile data from Redux store
  const currency = useSelector((state: RootState) => state.country.currency); // Access mobile data from Redux store
  const mobileData = useSelector((state: RootState) => state.mobiles.mobiles); // Access mobile data from Redux store
  const mobileDetail = mobileData.find((mobile) => mobile.id === id); // Filter the specific mobile based on id
  const handleRating = (rate: number) => {};
  const onPointerEnter = () => {};
  const onPointerLeave = () => {};
  const onPointerMove = (value: number, index: number) => {};
  // const onPointerEnter = () => console.log("Enter");
  // const onPointerLeave = () => console.log("Leave");
  // const onPointerMove = (value: number, index: number) =>
  //   console.log(value, index);

  useEffect(() => {
    if (mobileDetail) {
      setDocuments([mobileDetail]);
      setIsLoading(false);
    }
  }, [mobileDetail]);

  const renderSEO = () => {
    if (!mobileDetail) return null;

    const title = `${mobileDetail.brandName} ${mobileDetail.modelName} - Detailed Specifications and Prices | Ibad Mobiles`;
    const description = `Get detailed information on ${mobileDetail.brandName} ${mobileDetail.modelName}, including specifications, features, and prices. Compare and find the best deals on Ibad Mobiles.`;
    const keywords = `${mobileDetail.brandName}, ${mobileDetail.modelName}, specifications, features, prices, mobile phones, smartphones`;

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta
          name="google-adsense-account"
          content="ca-pub-3818311597459709"
        ></meta>
      </Helmet>
    );
  };

  // const GetMobileSpecificationData = () => {
  //   try {
  //     const mobileData = [];

  //     if (mobileDetail) {
  //       const data = JSON.parse(JSON.stringify(mobileDetail)); // Clone the mobileDetail to avoid mutating the original data

  //       if (typeof data.colors === "string") {
  //         data.colors = data.colors.split(",");
  //       }

  //       mobileData.push(data);
  //     }

  //     setDocuments(mobileData); // Update your component state
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error("Error processing mobile data: ", error);
  //     setIsLoading(false); // Ensure loading state is updated in case of error
  //   }
  // };

  // useEffect(() => {
  //   setIsLoading(true);
  //   GetMobileSpecificationData();
  // }, [id]); // Trigger the fetch when the `id` parameter changes.

  const handleStorageClick = (index: number) => {
    setSelectedStorage(index);
    // GetMobileSpecificationData();
  };
  const getRating = (mData: any) => {
    const Added =
      mData.audioRating / 200 +
      mData.batteryRating / 200 +
      mData.bluetoothRating / 200 +
      mData.chargingRating / 200 +
      mData.chipsetRating / 200 +
      mData.cpuRating / 200 +
      mData.displayTypeRating / 200 +
      mData.frontCameraRating / 200 +
      mData.gpuRating / 200 +
      mData.internalStorageRating / 200 +
      mData.mainCameraFeaturesRating / 200 +
      mData.mainCameraRating / 200 +
      mData.networkRating / 200 +
      mData.priceRating / 200 +
      mData.radioRating / 200 +
      mData.resolutionRating / 200 +
      mData.sensorsRating / 200 +
      mData.simRating / 200 +
      mData.sizeRating / 200 +
      mData.usbRating / 200 +
      mData.weightRating / 200 +
      mData.wifiTechnologyRating / 200;
    const result = Added / 22;
    return result < 2
      ? 3
      : result < 3 && result > 2
      ? 4
      : result > 3 && result < 4
      ? 4
      : 4.5;
  };

  return (
    <>
      {!isLoading && mobileDetail ? (
        <>
          {renderSEO()}
          {documents.map((items, i) => {
            return (
              <div className="container mb-5" key={i}>
                <div className="row mb-3 mt-5">
                  <div className="col">
                    <nav aria-label="breadcrumb ">
                      <ol className="breadcrumb mb-0">
                        <li
                          className="breadcrumb-item"
                          style={{ fontSize: "12px", fontWeight: "300" }}
                        >
                          <Link className="text-dark" to="/">
                            Home
                          </Link>
                        </li>
                        <li
                          className="breadcrumb-item "
                          style={{ fontSize: "12px", fontWeight: "300" }}
                        >
                          <Link
                            to={`/${slugs.new_mobiles}?reset=reset`}
                            className="text-dark d-inline"
                          >
                            Mobiles
                          </Link>
                        </li>
                        <li
                          className="breadcrumb-item "
                          aria-current="page"
                          style={{ fontSize: "12px", fontWeight: "300" }}
                        >
                          {" "}
                          <Link
                            to={`/${slugs.new_mobiles}?brand=${items.brandName}&reset=reset`}
                            className="text-dark d-inline"
                          >
                            {items.brandName}
                          </Link>
                        </li>
                        <li
                          className="breadcrumb-item active text-info"
                          aria-current="page"
                          style={{ fontSize: "12px", fontWeight: "400" }}
                        >
                          {items.modelName}
                        </li>
                      </ol>
                    </nav>
                    <h3
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "800",
                        fontSize: "24px",
                      }}
                    >
                      {items.brandName} Phones Details in {country}
                    </h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col col-md-6 ">
                    <div className="row">
                      <div className="col ">
                        <ImageGallery
                          items={items.imageUrls.map((url) => ({
                            original: url,
                            thumbnail: url,
                          }))}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col col-md-6 ">
                    <div className="header d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between py-2">
                      <h3>{items.modelName}</h3>

                      <div className="share__btn d-flex">
                        <Link
                          to={`/${slugs.comparison}?model=${items.modelName}`}
                          className="border-0 me-2 py-2 px-2 text-white"
                          style={{
                            backgroundColor: "#7b2cbf",
                            borderRadius: "8px",
                            fontSize: "16px",
                          }}
                        >
                          {" "}
                          <img
                            src={arrow}
                            alt="arrow"
                            width="25px"
                            height="15px"
                          />
                          Compare
                        </Link>
                        <button
                          className="border-0 me-2 py-2"
                          style={{
                            backgroundColor: "#f4eefa",
                            borderRadius: "8px",
                          }}
                        >
                          <ShareIcon
                            style={{
                              height: "19px",
                              width: "26px",
                              color: "#7b2cbf",
                              marginBottom: "5px",
                            }}
                          />
                        </button>
                        <button
                          className="border-0 py-2"
                          style={{
                            backgroundColor: "#f4eefa",
                            borderRadius: "8px",
                          }}
                        >
                          <FavoriteBorderIcon
                            style={{
                              height: "19px",
                              width: "26px",
                              color: "#7b2cbf",
                              marginBottom: "5px",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                    <p style={{ fontSize: "17px" }}>
                      <Rating
                        readonly={true}
                        // onClick={handleRating}
                        // onPointerEnter={onPointerEnter}
                        // onPointerLeave={onPointerLeave}
                        // onPointerMove={onPointerMove}
                        size={16}
                        // className="rating"
                        initialValue={getRating(mobileDetail)}
                      />{" "}
                      {mobileDetail.mainCameraRating} Reviews
                    </p>
                    <p>
                      Brand: &ensp;
                      <span style={{ color: "#7b2cbf" }}>
                        {items.brandName}
                      </span>
                    </p>
                    <div style={{ cursor: "pointer" }}>
                      Market Price <br />
                      <h3>
                        {currency || "$"}{" "}
                        {items.variants[selectedStorage]?.priceInCurrency}
                      </h3>
                    </div>
                    <h5 className="mt-4 mb-3">Colors</h5>
                    <div className="row ">
                      <div className="col d-flex ">
                        {items.colors && Array.isArray(items.colors) ? (
                          items.colors.map((color, index) => (
                            <div
                              key={index}
                              style={{
                                height: "32px",
                                width: "32px",
                                backgroundColor: color,
                                borderRadius: "100%",
                                border: "1px solid rgba(0,0,0,0.4)",
                              }}
                              className="mx-2"
                            ></div>
                          ))
                        ) : (
                          <div>No colors available</div>
                        )}
                      </div>
                    </div>
                    <h5 className="mt-4">Storage Capacity</h5>
                    <div>
                      {items.variants.map((variant, index) => (
                        <button
                          key={index}
                          style={{
                            height: "40px",
                            width: "110px",
                            borderRadius: "10px",
                            border:
                              selectedStorage === index
                                ? "2px solid #7b2cbf"
                                : "none",
                            marginRight: "15px",
                            backgroundColor:
                              selectedStorage === index ? "#f8f9fa" : "white",
                            color:
                              selectedStorage === index ? "#7b2cbf" : "#7b2cbf",
                          }}
                          onClick={() => handleStorageClick(index)} // Bind the click event here
                        >
                          {variant.ram}/
                          {variant.internalStorage === 1000
                            ? 1024
                            : variant.internalStorage}{" "}
                          GB
                        </button>
                      ))}
                    </div>
                    <div className="col">
                      <h5 className="mt-4">Product Description</h5>
                      <p style={{ color: "black" }}>{items?.description}</p>
                    </div>
                  </div>
                </div>
                {/* <div className="row mt-5 mb-3">
                  <div className="col my-3 d-flex justify-content-center ">
                    <h2
                      className="mx-3"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        borderBottomColor: "#9D4EDD",
                        borderBottomWidth: "3px",
                        borderBottomStyle: "solid",
                      }}
                    >
                      Specifications
                    </h2>
                    <h2
                      className="mx-3"
                      style={{ fontSize: "16px", fontWeight: "300" }}
                    >
                      Reviews
                    </h2>
                    <h2
                      className="mx-3"
                      style={{ fontSize: "16px", fontWeight: "300" }}
                    >
                      FAQ's
                    </h2>
                  </div>
                </div> */}
                <div className="row">
                  <div className="col my-3 text-center">
                    <h2 style={{ fontSize: "24px", fontWeight: "700" }}>
                      Specifications
                    </h2>
                  </div>
                </div>
                <div
                  className="box"
                  style={{ maxWidth: "826px", margin: "0 auto" }}
                >
                  <div className="row bg-secondary">
                    <div className="col  ">
                      <h4
                        className="rounded"
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          paddingLeft: "30px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          margin: "20px 5px",
                          backgroundColor: "#FFFFFF",
                        }}
                      >
                        General Features
                      </h4>
                      <div
                        className="d-flex flex-column justify-content-around mx-5"
                        style={{ color: "#858586" }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Release Date
                          </span>
                          <span className="text-prop-value text-end">
                          {items ? (
                          items.dateCreated ? (
                            typeof items.dateCreated === "string" ? (
                              new Date(items.dateCreated).toLocaleDateString(
                                "en-GB"
                              ) // UK format: day/month/year
                            ) : (
                              new Date(
                                items.dateCreated._seconds * 1000
                              ).toLocaleDateString("en-GB")
                            )
                          ) : (
                            ""
                          )
                        ) : (
                          <span className="dull">N/A</span>
                        )}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            SIM Support
                          </span>
                          <span className="text-prop-value text-end">
                            {items.sim}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Phone Dimensions
                          </span>
                          <span className="text-prop-value text-end">
                            {items.dimensions}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Phone Weight
                          </span>
                          <span className="text-prop-value text-end">
                            {items.weight}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Operating System
                          </span>
                          <span className="text-prop-value text-end">
                            {items.os}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row bg-secondary">
                    <div className="col  ">
                      <h4
                        className="rounded"
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          paddingLeft: "30px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          margin: "20px 5px",
                          backgroundColor: "#FFFFFF",
                        }}
                      >
                        Display
                      </h4>
                      <div
                        className="d-flex flex-column justify-content-around mx-5"
                        style={{ color: "#858586" }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Screen Size
                          </span>
                          <span className="text-prop-value text-end">
                            {items.size}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Screen Resolution
                          </span>
                          <span className="text-prop-value text-end">
                            {items.resolution}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Screen Type
                          </span>
                          <span className="text-prop-value text-end">
                            {items.displayType}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Screen Protection
                          </span>
                          <span className="text-prop-value text-end">
                            Corning Gorilla Glass 5
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row bg-secondary">
                    <div className="col  ">
                      <h4
                        className="rounded"
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          paddingLeft: "30px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          margin: "20px 5px",
                          backgroundColor: "#FFFFFF",
                        }}
                      >
                        Memory
                      </h4>
                      <div
                        className="d-flex flex-column justify-content-around mx-5"
                        style={{ color: "#858586" }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Internal Memory
                          </span>
                          <span className="text-prop-value text-end">
                            {items.variants[0].internalStorage === 1000
                              ? 1024
                              : items.variants[0].internalStorage}{" "}
                            GB
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            RAM
                          </span>
                          <span className="text-prop-value text-end">
                            {items.variants[0].ram} GB
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Card Slot
                          </span>
                          <span className="text-prop-value text-end">
                            {items.cardSlot == "true" ? "Yes" : "No"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row bg-secondary">
                    <div className="col  ">
                      <h4
                        className="rounded"
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          paddingLeft: "30px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          margin: "20px 5px",
                          backgroundColor: "#FFFFFF",
                        }}
                      >
                        Performance
                      </h4>
                      <div
                        className="d-flex flex-column justify-content-around mx-5"
                        style={{ color: "#858586" }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            CPU
                          </span>
                          <span className="text-prop-value text-end">
                            {items.cpu}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Chipset
                          </span>
                          <span className="text-prop-value text-end">
                            {items.chipset}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            GPU
                          </span>
                          <span className="text-prop-value text-end">
                            {items.gpu}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row bg-secondary">
                    <div className="col  ">
                      <h4
                        className="rounded"
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          paddingLeft: "30px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          margin: "20px 5px",
                          backgroundColor: "#FFFFFF",
                        }}
                      >
                        Battery
                      </h4>
                      <div
                        className="d-flex flex-column justify-content-around mx-5"
                        style={{ color: "#858586" }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Battery
                          </span>
                          <span className="text-prop-value text-end">
                            {items.battery}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Charging
                          </span>
                          <span className="text-prop-value text-end">
                            {items.charging}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row bg-secondary">
                    <div className="col  ">
                      <h4
                        className="rounded"
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          paddingLeft: "30px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          margin: "20px 5px",
                          backgroundColor: "#FFFFFF",
                        }}
                      >
                        Camera
                      </h4>
                      <div
                        className="d-flex flex-column justify-content-around mx-5"
                        style={{ color: "#858586" }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Front Camera
                          </span>
                          <span className="text-prop-value text-end">
                            {items.frontCamera}
                          </span>
                        </div>
                        {/* <div  className='d-flex justify-content-between align-items-center' >
                                        <span className='my-1' style={{ fontWeight: "500", }}>Front Flash Light</span>
                                        <span className='text-end'></span>
                                    </div> */}
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Back Flash Light
                          </span>
                          <span className="text-prop-value text-end">True</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Back Camera
                          </span>
                          <span className="text-prop-value text-end">
                            {items.mainCamera}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Back Camera Features
                          </span>
                          <span className="text-prop-value text-end">
                            {items.mainCameraFeatures}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row bg-secondary">
                    <div className="col  ">
                      <h4
                        className="rounded"
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          paddingLeft: "30px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          margin: "20px 5px",
                          backgroundColor: "#FFFFFF",
                        }}
                      >
                        Connectivity
                      </h4>
                      <div
                        className="d-flex flex-column justify-content-around mx-5"
                        style={{ color: "#858586" }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Bluetooth
                          </span>
                          <span className="text-prop-value text-end">
                            {items.bluetooth}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Network
                          </span>
                          <span className="text-prop-value text-end">
                            {items.network}
                          </span>
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Radio
                          </span>
                          <span className="text-prop-value text-end">
                            {items.radio}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            WiFi
                          </span>
                          <span className="text-prop-value text-end">
                            {items.wifiTechnology}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            NFC
                          </span>
                          <span className="text-prop-value text-end">
                            {items.nfc}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className="col">
          <div className="loading d-flex justify-content-center ">
            <img src={loader} alt="" style={{ mixBlendMode: "multiply" }} />
          </div>
        </div>
      )}
    </>
  );
};
export default MobileDetails;
