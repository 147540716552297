import { Link } from "react-router-dom";
import "./blogs.scss";

import { BlogDataInterface, formatDateFromTimestamp } from "./utils";
import slugs from "../../../config/slugConfig";

const BlogCard = ({ blog }: { blog: BlogDataInterface }) => {
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  const blogDate = new Date(blog.date._seconds * 1000);
  const isLatest = blogDate >= sevenDaysAgo;

  // Filter and sort blogs from the last 7 days
  // let latestBlogs = blogsData
  //   .filter((blog) => {
  //     const blogDate = new Date(blog.date._seconds * 1000);
  //     return blogDate >= sevenDaysAgo;
  //   })
  //   .sort((a, b) => {
  //     return b.date._seconds - a.date._seconds;
  //   });

  return (
    // <div className="blog-card-recommended col-12 col-md-3 g-4 mb-4">
    <Link
      to={`/${slugs.blogs}/${blog.title}`}
      className="blog-card-recommended col-12 col-md-3 g-4 mb-5"
    >
      <div className="blog-card-image-container">
        <img src={blog.image} className="blog-card-image" />
        <div className="image-overlay"></div>
      </div>
      <div>
        <button
          className="latest-news-btn"
          style={{ visibility: isLatest ? "visible" : "hidden" }}
        >
          Latest News
        </button>
        <h5 className="blog-card-title">
          {blog.title.length > 48
            ? blog.title.substring(0, 70) + "..."
            : blog.title}
        </h5>
        <p className="blog-card-text">{blog.summary}</p>
        <div className="blog-card-footer">
          <small className="text-muted">{blog.readTime} read</small>
          <small className="text-muted">
            {formatDateFromTimestamp(blog.date)}
          </small>
        </div>
      </div>
    </Link>
    // </div>
  );
};

export default BlogCard;
