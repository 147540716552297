import { configureStore } from '@reduxjs/toolkit';
import mobilesReducer from './slice/mobilesSlice';
import countryReducer from './slice/countrySlice';

export const store = configureStore({
    reducer: {
        mobiles: mobilesReducer,
        country: countryReducer,
    },
});

// Infer the RootState and AppDispatch types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
