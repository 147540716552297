import "./style.scss";
import CloseIcon from "@mui/icons-material/Close";

const Overlay = ({ children, onClose }: any) => {
  return (
    <div className="overlay">
      <div className="overlay-content">
        <div className="close-icon" onClick={onClose}>
          <CloseIcon />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Overlay;