import React from "react";

const Stepper = ({ activeState }: any) => {
  const steps = [
    "About Mobile",
    "Brand/Model",
    "Upload Photos",
    "Add Location",
    "Review Details",
  ];

  return (
    <div className="stepper-container">
      <ul className="stepper">
        {steps.map((label, index) => (
          <li
            className={`step ${index + 1 <= activeState ? "active" : ""} ${
              index + 1 == activeState ? "current-active" : ""
            }`}
            key={index}
          >
            <div className="circle">{index + 1}</div>
            <div className="label">{label}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Stepper;
