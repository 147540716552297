import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "70vh" }}
    >
      <Helmet>
        <title>404 Not Found | Ibad Mobiles</title>
        <meta
          name="description"
          content="The page you are looking for might have been removed, had its name changed, or is temporarily unavailable on Ibad Mobiles."
        />
        <meta name="robots" content="noindex" />
        <meta name="google-adsense-account" content="ca-pub-3818311597459709"></meta>
      </Helmet>
      <div className="w-25 text-center">
        <h5 className="text-info">404 Error</h5>
        <h1 className="text-muted ">Page Not Found</h1>
        <p>
          Sorry, the page you are looking for could not be found or has been
          removed.
        </p>
        <Link to={"/"}>
          <button className="btn btn-sm btn-info rounded mt-2 py-2 px-4 fs-6">
            Go to Home
          </button>
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
