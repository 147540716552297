import { Helmet } from "react-helmet";
import AboutUsBanner from "./AboutUsBanner";
import MeetOutTeam from "./MeetOutTeam";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About Ibad Mobiles - Our Story, Mission, and Team</title>
        <meta
          name="description"
          content="Learn about Ibad Mobiles, our journey in the mobile industry, our mission, values, and the passionate team behind our success."
        />
        <meta
          name="keywords"
          content="about Ibad Mobiles, Ibad Mobiles team, mobile industry, our mission"
        />
      </Helmet>
      <AboutUsBanner />
      <MeetOutTeam />
    </>
  );
};

export default AboutUs;
