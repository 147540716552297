import "./aboutus.scss";
import Male from "../../../assets/male_profile_placeholder.png";
import Female from "../../../assets/female_profile_placeholder.png";
import BilalImg from "../../../assets/bilal_faisal_profile.jpg";
import IbaadImg from "../../../assets/ibaadurrahmanprofile.jpg";
import anamProfile from "../../../assets/anamrehmanprofile.jpeg";
import attiqaNasser from "../../../assets/attiqaNaseerprofile.jpeg";
import minahilRashid from "../../../assets/minahilrashid.jpg";
import usamaAhmad from "../../../assets/usamaahmad.jpg"
import { Google, LinkedIn } from "@mui/icons-material";
import { Icon } from "@mui/material";

const MeetOutTeam = () => {
  return (
    <div className="meetOurTeam">
      <div className="container-fluid">
        <div className="row mx-0 pt-t py-md-4 px-2">
          <div className="offset-md-4 col-md-4 text-center">
            <h4>Meet our team</h4>
            <p className="fw-light">
              As you get to know us, you'll discover the dedication, creativity,
              and commitment that fuel our success.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="row g-5 mb-md-5 mx-0 px-4">
            <div className="col-12 col-md-1 col-lg-2"></div>
            <div className="col-12 col-md-5 col-lg-4">
              <div className="card custom-card">
                <div className="card-body text-center">
                  <div>
                    <div className="avatar mx-auto">
                      <img
                        src={IbaadImg}
                        alt="Avatar"
                        className="rounded-circle"
                      />
                    </div>
                    <h5 className="card-title mt-3">Ibaad Ur Rehman</h5>
                    <h6 className="card-subtitle mb-2">
                      Founder | Director | CTO
                    </h6>
                  </div>
                  <p className="card-text">
                    Empowering users through innovative mobile solutions, Ibaad
                    is the visionary behind our website.
                  </p>
                  <div className="social-links">
                    <a
                      href="mailto:abc@gmail.com"
                      target="_blank"
                      className="card-link"
                    >
                      <Icon component={Google} color="secondary" />
                    </a>
                    <a
                      href="https://www.linkedin.com"
                      target="_blank"
                      className="card-link"
                    >
                      <Icon component={LinkedIn} color="secondary" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-4">
              <div className="card custom-card">
                <div className="card-body text-center">
                  <div>
                    <div className="avatar mx-auto">
                      <img
                        src={attiqaNasser}
                        alt="Avatar"
                        className="rounded-circle"
                      />
                    </div>
                    <h5 className="card-title mt-3">Attiqa Naseer</h5>
                    <h6 className="card-subtitle mb-2">
                      Cheif Executive Officer
                    </h6>
                  </div>
                  <p className="card-text">
                    Driving ibadmobile to be the go-to source for mobile
                    insights worldwide.
                  </p>
                  <div className="social-links">
                    <a
                      href="mailto:abc@gmail.com"
                      target="_blank"
                      className="card-link"
                    >
                      <Icon component={Google} color="secondary" />
                    </a>
                    <a
                      href="https://www.linkedin.com"
                      target="_blank"
                      className="card-link"
                    >
                      <Icon component={LinkedIn} color="secondary" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-1 col-lg-2"></div>
          </div>
          <div className="row g-5 g-md-3 g-lg-5 mx-0 px-4">
            <div className="col-12 col-md-4">
              <div className="card custom-card">
                <div className="card-body text-center">
                  <div>
                    <div className="avatar mx-auto">
                      <img
                        src={BilalImg}
                        alt="Avatar"
                        className="rounded-circle"
                      />
                    </div>
                    <h5 className="card-title mt-3">Bilal Faisal</h5>
                    <h6 className="card-subtitle mb-2">Software Developer</h6>
                  </div>
                  <p className="card-text">
                    Crafting seamless web experiences with dedication and
                    passion. Let's build something remarkable together!
                  </p>
                  <div className="social-links">
                    <a
                      href="mailto:bilalfaisal.developer@gmail.com"
                      target="_blank"
                      className="card-link"
                    >
                      <Icon component={Google} color="secondary" />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/bilal-faisal/"
                      target="_blank"
                      className="card-link"
                    >
                      <Icon component={LinkedIn} color="secondary" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="card custom-card">
                <div className="card-body text-center">
                  <div>
                    <div className="avatar mx-auto">
                      <img
                        src={minahilRashid}
                        alt="Avatar"
                        className="rounded-circle"
                      />
                    </div>
                    <h5 className="card-title mt-3">Minahil Rashid</h5>
                    <h6 className="card-subtitle mb-2">Creative Manager</h6>
                  </div>
                  <p className="card-text">
                    Creative visionary and team leader, driving design
                    excellence at the forefront of innovation for ibadmobile.
                  </p>
                  <div className="social-links">
                    <a
                      href="mailto:abc@gmail.com"
                      target="_blank"
                      className="card-link"
                    >
                      <Icon component={Google} color="secondary" />
                    </a>
                    <a
                      href="https://www.linkedin.com"
                      target="_blank"
                      className="card-link"
                    >
                      <Icon component={LinkedIn} color="secondary" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="card custom-card">
                <div className="card-body text-center">
                  <div>
                    <div className="avatar mx-auto">
                      <img
                        src={anamProfile}
                        alt="Avatar"
                        className="rounded-circle"
                      />
                    </div>
                    <h5 className="card-title mt-3">Anam Rehman</h5>
                    <h6 className="card-subtitle mb-2">
                      Data Entry Specialist
                    </h6>
                  </div>
                  <p className="card-text">
                    Experienced data entry specialist at Ibad Mobiles, ensuring
                    precision, efficiency, confidentiality, and data integrity.
                  </p>
                  <div className="social-links">
                    <a
                      href="mailto:abc@gmail.com"
                      target="_blank"
                      className="card-link"
                    >
                      <Icon component={Google} color="secondary" />
                    </a>
                    <a
                      href="https://www.linkedin.com"
                      target="_blank"
                      className="card-link"
                    >
                      <Icon component={LinkedIn} color="secondary" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="card custom-card">
                <div className="card-body text-center">
                  <div>
                    <div className="avatar mx-auto">
                      <img
                        src={usamaAhmad}
                        alt="Avatar"
                        className="rounded-circle"
                      />
                    </div>
                    <h5 className="card-title mt-3">Usama Ahmad</h5>
                    <h6 className="card-subtitle mb-2">
                      Blogs Specialist
                    </h6>
                  </div>
                  <p className="card-text">
                  Passionate wordsmith crafting engaging narratives and insightful content to inspire and inform readers
                  </p>
                  <div className="social-links">
                    <a
                      href="mailto:abc@gmail.com"
                      target="_blank"
                      className="card-link"
                    >
                      <Icon component={Google} color="secondary" />
                    </a>
                    <a
                      href="https://www.linkedin.com"
                      target="_blank"
                      className="card-link"
                    >
                      <Icon component={LinkedIn} color="secondary" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetOutTeam;
