import React from 'react'
// import FeaturedMobileCard from './FeaturedMobileCard'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './testimonial.scss'
import TestimonialCard from './TestimonialCard';
import { Interface } from 'readline';

export interface TestimonialPerson {
  id: number;
  image: string;
  name: string;
  place:string;
  review:string
  rating:number;

}
const Testimonial: React.FC = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };
  const testimonial: TestimonialPerson[] = [
    {
      name: "John Doe",
      id: 1,
      image: 'https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg',
      place: 'New York, NY',
      review: 'I love this website! It has been a great resource for staying up-to-date with the latest mobile information.',
      rating: 5
    },
    {
      name: "Alice Smith",
      id: 2,
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-iUaDOq6WP3thUPgxHPX9ifMWw2D1oRy1lUSdqeAfGWoEKPxBSTVsP9SgsWPVim2LelE&usqp=CAU',
      place: 'Los Angeles, CA',
      review: 'The information provided on this site is fantastic. I visit it daily to read the latest mobile news.',
      rating: 4
    },
    {
      name: "Bob Johnson",
      id: 3,
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDoIrrONpSbM7m8g0_REAiB1rJtJFYrlESD3JXO_49Du3R0MotbDOAOUQIXtBDmULP-TY&usqp=CAU',
      place: 'Chicago, IL',
      review: 'This website has helped me make informed decisions when buying a new mobile phone. Highly recommended!',
      rating: 5
    },
    {
      name: "Emily Brown",
      id: 4,
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_46FCDXFt0tAMoeWM_Wbt405yEdIJXEKcDHGaLlfoeIcBJkw3AuQJxv2_itjmg8P_jzY&usqp=CAU',
      place: 'San Francisco, CA',
      review: `I'm a mobile enthusiast, and this site is a goldmine of information. Keep up the good work!`,
      rating: 5
    },
    {
      name: "Michael Wilson",
      id: 5,
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDoIrrONpSbM7m8g0_REAiB1rJtJFYrlESD3JXO_49Du3R0MotbDOAOUQIXtBDmULP-TY&usqp=CAU',
      place: 'Houston, TX',
      review: `I can't thank this website enough for helping me understand the complex world of mobile technology.`,
      rating: 4
    },
    {
      name: "Sarah Lee",
      id: 6,
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-iUaDOq6WP3thUPgxHPX9ifMWw2D1oRy1lUSdqeAfGWoEKPxBSTVsP9SgsWPVim2LelE&usqp=CAU',
      place: 'Miami, FL',
      review: 'The mobile reviews here are on point. I rely on this site to choose my next smartphone.',
      rating: 5
    },
    {
      name: "David Clark",
      id: 7,
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDoIrrONpSbM7m8g0_REAiB1rJtJFYrlESD3JXO_49Du3R0MotbDOAOUQIXtBDmULP-TY&usqp=CAU',
      place: 'Dallas, TX',
      review: `This website has saved me time and money. It's a must-visit for mobile tech enthusiasts.`,
      rating: 4
    }
  ];
  return (
    <>
      <div className="testimonial" style={{ margin: "50px auto" }}>
        <div className='container  py-5 ' >
          <div className="row  ">
            <div className='col '>
              <h3 className='header__Top text-white' >What Are The Viewers Saying About Us</h3>
              <p className='header__Bottom text-white'>Helping you navigate your way to the perfect device</p>
            </div>
          </div>
            <Slider {...settings} className='py-2 custom-slider overflow-hidden'>
            <div>
                <TestimonialCard name={testimonial[0].name} id ={testimonial[0].id} rating={testimonial[0].rating} review ={testimonial[0].review}place={testimonial[0].place} image ={testimonial[0].image}/>
              </div>
              <div>
                <TestimonialCard name={testimonial[1].name} id ={testimonial[1].id} rating={testimonial[1].rating} review ={testimonial[1].review}place={testimonial[1].place} image ={testimonial[1].image}/>
              </div>
              <div>
                <TestimonialCard name={testimonial[2].name} id ={testimonial[2].id} rating={testimonial[2].rating} review ={testimonial[2].review}place={testimonial[2].place} image ={testimonial[2].image} />
              </div>
              <div>
                <TestimonialCard name={testimonial[3].name} id ={testimonial[3].id} rating={testimonial[3].rating} review ={testimonial[3].review}place={testimonial[3].place} image ={testimonial[3].image} />
              </div>
              <div>
                <TestimonialCard name={testimonial[4].name} id ={testimonial[4].id} rating={testimonial[4].rating} review ={testimonial[4].review}place={testimonial[4].place} image ={testimonial[4].image}/>
              </div>
              <div>
                <TestimonialCard name={testimonial[5].name} id ={testimonial[5].id} rating={testimonial[5].rating} review ={testimonial[5].review}place={testimonial[5].place} image ={testimonial[5].image} />
              </div>
              <div>
                <TestimonialCard  name={testimonial[6].name} id ={testimonial[6].id} rating={testimonial[6].rating} review ={testimonial[6].review}place={testimonial[6].place} image ={testimonial[6].image}/>
              </div>
            </Slider>
            <div className="row mb-5 ">
              <div className="col">
                <p className='text-white ' style={{fontWeight:"500",fontSize:"14px"}}>12.7K+ Reviews</p>
              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default Testimonial;