import "./ads.scss";
import { Helmet } from "react-helmet";
import Breadcrumb from "./Breadcrumb";
import Ads from "./Ads";

const UserAds = () => {
  return (
    <div className="container my-5 user-ads-parent">
      <Helmet>
        <title>User Ads | Ibad Mobile</title>
        {/* <meta name="description" content="" />
        <meta name="keywords" content="" /> */}
      </Helmet>
      <Breadcrumb />
      <Ads />
    </div>
  );
};

export default UserAds;
