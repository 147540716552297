import "./style.scss";
import { Helmet } from "react-helmet";
import Breadcrumb from "./Breadcrumb";
import { Fragment } from "react";
import ContactForm from "./ContactForm";
import ContactSection from "./ContactSection";

const Contact = () => {
  return (
    <div className="contact-us">
      <div className="container my-5">
        <Helmet>
          <title></title>
          <meta name="description" content="" />
          <meta name="keywords" content="" />
        </Helmet>
        <Breadcrumb />
        <div className="row">
          <div className="col-12 col-md-6">
            <ContactSection />
          </div>
          <div className="col-12 col-md-6">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
