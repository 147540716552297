import React, { useState, useEffect } from "react";
import FeaturedMobileCard from "./FeaturedMobileCard";
import Slider from "react-slick";
import "./featured.scss";
import { Link } from "react-router-dom";
import { MobileData } from "./interface2";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import slugs from "../../config/slugConfig";

const FeaturedMobile: React.FC = () => {
  const mobileData: MobileData[] = useSelector(
    (state: RootState) => state.mobiles.mobiles
  );

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="container " style={{ margin: "100px auto" }}>
      <div className="row">
        <div className="col d-flex justify-content-between">
          <span></span>
          <h1
            style={{ fontSize: "24px", fontWeight: "800", textAlign: "center" }}
          >
            Featured New Mobiles
          </h1>
          <Link to={slugs.new_mobiles} className="btn btn-outline-info  ">
            View All
          </Link>
        </div>
      </div>
      <Slider {...settings} className="mt-5 mx-5 custom-slider overflow-hidden">
        {mobileData
          .filter((mobile) => mobile.featured === "Featured")
          .map((mobile, i) => (
            <div key={i}>
              <FeaturedMobileCard mobile={mobile} />
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default FeaturedMobile;
