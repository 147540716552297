import React, { useState } from "react";
import BlogCard from "./BlogCardRecommended";

import { BlogDataInterface, formatDateFromTimestamp } from "./utils";

interface BlogsBottomSectionProps {
  blogsData: BlogDataInterface[];
}

const BlogsBottomSection: React.FC<BlogsBottomSectionProps> = ({
  blogsData,
}) => {
  // const recommendedBlogs = blogsData.filter((blog) => blog.recommended == true);

  const [viewAll, setViewAll] = useState(false);
  return (
    <div className="container-fluid">
      <div className="row mt-5 gx-5 gx-md-3 px-0 mx-0">
        <div className="header d-flex align-items-center justify-content-between mb-2 px-2">
          <h1 className="ms-4" style={{ fontSize: "20px", fontWeight: "bold" }}>
            Recommended Blogs
          </h1>
          {blogsData.length > 0 && (
            <p
              style={{ minWidth: "80px", marginLeft: "10px" }}
              className="btn btn-outline-info btn-sm me-4 "
              onClick={() => setViewAll(!viewAll)}
            >
              {viewAll ? "View Less" : "View All"}
            </p>
          )}
        </div>

        {blogsData.length > 0 ? (
          (viewAll ? blogsData : blogsData.slice(0, 4)).map(
            (blog) => <BlogCard key={blog.id} blog={blog} />
          )
        ) : (
          <div className="container my-3">
            <p className="text-center">No recommended blogs found.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogsBottomSection;
