import React from 'react'
import DoneIcon from '@mui/icons-material/Done';
import menwithphone from '../../../assets/menwithphone.png'
const ComparisonAdvertisingCard: React.FC = () => {
    return (
        <div className="comparisonAdvertisingCard">
            <div className="card bg-info  " style={{ width: '16rem', borderRadius: "30px", marginTop: "111px" }}>
                <img src={menwithphone} style={{ marginTop: "-135px" }} alt="person" width="180px" className='mx-auto' />

                <div className="card " style={{ width: '16rem', borderRadius: "30px", backgroundColor: "#f8f9fa", marginTop: "-10px" }}>
                    <div className="card-body">
                        <h6 className="card-title fw-bold">Compare and Get Your</h6>
                        <h5 className="card-title text-info fw-bold">Perfect Smartphone</h5>
                        <div className="qualities py-1">
                            <p className="card-text" style={{ fontSize: "14px" }} ><DoneIcon className='text-info me-2' /> Build Material and Durability</p>
                            <p className="card-text" style={{ fontSize: "14px" }} ><DoneIcon className='text-info me-2' /> Display Quality</p>
                            <p className="card-text" style={{ fontSize: "14px" }} ><DoneIcon className='text-info me-2' /> Performance</p>
                            <p className="card-text" style={{ fontSize: "14px" }} ><DoneIcon className='text-info me-2' /> Battery Life</p>
                            <p className="card-text" style={{ fontSize: "14px" }} ><DoneIcon className='text-info me-2' /> Camera Capabilities</p>
                            <p className="card-text" style={{ fontSize: "14px" }} ><DoneIcon className='text-info me-2' /> Connectivity</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComparisonAdvertisingCard