import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Overlay from "./Overlay";
import Home from "../Home/Home";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  db,
  auth,
  googleProvider,
  signInWithPopup,
} from "../../../config/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAuth } from "../../../context/AuthContext";
import { doc, getDoc } from "firebase/firestore";

function SignInPage() {
  const [showOverlay, setShowOverlay] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { dispatch } = useAuth();

  const handleClose = () => {
    setShowOverlay(false);
    navigate("/");
  };

  // const handleSignIn = async (e: React.FormEvent) => {
  //   e.preventDefault();
  //   setError(null);

  //   try {
  //     const userCredential = await signInWithEmailAndPassword(
  //       auth,
  //       email,
  //       password
  //     );
  //     console.log("User signed in: ", userCredential.user);
  //     dispatch({ type: "LOGIN", payload: { user: userCredential.user } });

  //     // Fetch additional user details from Firestore
  //     const userDocRef = doc(db, "users", userCredential.user.uid);
  //     const userDoc = await getDoc(userDocRef);

  //     if (userDoc.exists()) {
  //       console.log("Full User Details:", userDoc.data());
  //     } else {
  //       console.log("No user document found.");
  //     }

  //     navigate("/"); // Navigate to home page or dashboard after successful sign-in
  //   } catch (error: any) {
  //     console.error("Error during sign-in: ", error);
  //     setError(error.message); // Set the error message
  //   }
  // };

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      console.log("User signed in: ", user);

      // Fetch additional user details from Firestore
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);

      let additionalData = {};
      if (userDoc.exists()) {
        additionalData = userDoc.data(); // Get fullName and mobileNumber
        console.log("Full User Details:", additionalData);
      } else {
        console.log("No user document found.");
      }

      // Dispatch login action with additional user details
      dispatch({
        type: "LOGIN",
        payload: {
          user: {
            ...user,
            ...additionalData, // Spread the additional data into the user object
          },
        },
      });

      navigate("/"); // Navigate to home page or dashboard after successful sign-in
    } catch (error: any) {
      console.error("Error during sign-in: ", error);
      setError(error.message); // Set the error message
    }
  };

  const handleGoogleSignIn = async () => {
    setError(null); // Clear any previous errors

    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log("User signed in with Google: ", result.user);
      dispatch({ type: "LOGIN", payload: { user: result.user } });

      navigate("/"); // Navigate to home page or dashboard after successful sign-in
    } catch (error: any) {
      console.error("Error during Google sign-in: ", error);
      setError(error.message); // Set the error message
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <Home />
      {showOverlay && (
        <Overlay onClose={handleClose}>
          <div className="signin-form">
            <div className="text-center mb-4">
              <h2>Welcome</h2>
              <p>Sign in to find the perfect phone for you</p>
            </div>
            <form onSubmit={handleSignIn}>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div
                    className="input-group-append"
                    onClick={togglePasswordVisibility}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="input-group-text">
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <input type="checkbox" id="remember-me" />
                  <label htmlFor="remember-me" className="ml-2">
                    Remember me
                  </label>
                </div>
                <a href="#" className="forgot-password">
                  Forgot Password?
                </a>
              </div>
              {error && <div className="text-danger">{error}</div>}

              <button type="submit" className="btn btn-primary btn-block">
                Sign in
              </button>
              <div className="text-center mt-2">
                <span>or</span>
              </div>
              <button
                type="button"
                className="btn btn-secondary btn-block text-cotinue"
                onClick={handleGoogleSignIn}
              >
                Continue With Google
              </button>
              <div className="text-center mt-3 d-flex justify-content-center">
                <p className="mx-1">Don't have an account?</p>{" "}
                <a href="/signup">Sign up</a>
              </div>
            </form>
          </div>
        </Overlay>
      )}
    </>
  );
}

export default SignInPage;
