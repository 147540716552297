import { Helmet } from "react-helmet";
import Breadcrumb from "./Breadcrumb";
import "./style.scss";
import ParentStates from "./ParentStates";

const Screen2 = () => {
  return (
    <div className="container my-5 screen2">
      <Helmet>
        <title></title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <Breadcrumb />
      <ParentStates />
    </div>
  );
};

export default Screen2;
