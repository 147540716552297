import React from 'react'
import './videos.scss'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
interface LatestVideosCardProps {
    imgSrc?: string; // Make the prop optional if not all cards will have a video
}
const LatestVideosCard: React.FC<LatestVideosCardProps> = (props) => {
    return (
        <div className="card border-0 mx-3" style={{padding:0,margin:0}} >
            <iframe className='w-100' height="255" src={props.imgSrc} title="YouTube video player" style={{borderRadius:20}} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
            <div className="card-body my-1" style={{padding:0,margin:0}}>
                <div className="header d-flex justify-content-between align-items-center my-2 mb-2 ">
                    <div className="date d-flex align-items-center mx-2 ">
                    <CalendarTodayIcon style={{fontSize:'12px'}}/>
                    <p className="card-text ms-1"  style={{fontSize:'13px',fontWeight:'300',fontFamily:'Roboto'}}>14-08-2023</p>
                    </div>
                    <div className="category mx-4">
                        <p className="card-text" style={{fontSize:'13px',fontWeight:'300',fontFamily:'Roboto',}}>Category</p>
                    </div>
                </div>
                <p  style={{fontWeight:'700',fontFamily:"Roboto"}} >A Closer Look at [Mobile Model] - Pros, Cons, and Performance</p>
            </div>
        </div>
    )
}

export default LatestVideosCard;