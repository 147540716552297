import React from 'react'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import StarIcon from '@mui/icons-material/Star';
import testi from '../../assets/testi.jpg';
import icon from '../../assets/icons.png';
import './TestimonialCard.scss'
import { TestimonialPerson } from './Testimonial';

const TestimonialCard: React.FC <TestimonialPerson> = ({name,id,rating,review,place,image}) => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col col-md-6 col-lg-3 mb-4">
                    <div className="card testi__card mx-auto" style={{ borderRadius: '20px',width: '270px', height: '264px' }} >
                        <div className="card-avatar d-flex justify-content-between mx-2  mt-2 " >
                            <div className="profile d-flex  justify-content-center align-items-center">
                                <img src={image} className="img-fluid" alt="..." width='60px' style={{ maxWidth: "100px", borderRadius: '100%' }} />
                                <div className="name  d-flex flex-column justify-content-center align-items-center ms-2">
                                    <h6 style={{ fontSize: '15px', fontWeight: '700' }}>{name}</h6>
                                    <p style={{ fontSize: '12px', fontWeight: '300', color: '#968F9A' }}><PlaceOutlinedIcon style={{ fontSize: '16px' }} /> {place}</p>
                                </div>
                            </div>
                            <div className="rating">
                                <span>{rating}</span>
                                <StarIcon className='text-warning ms-1' style={{ fontSize: '18px' }} />
                            </div>
                        </div>
                        <div className="card-body">
                            <img src={icon} alt="" width='23px' className='ms-3 mb-2' />
                            <p className="card-text" style={{ fontSize: '14px' }}>{review}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestimonialCard;