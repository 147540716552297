import React from 'react'
import Navbar from './Navbar'

const index: React.FC = () => {
  return (
    <div>
      <Navbar />
    </div>
  )
}

export default index
