import "./mobiles.scss";
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate, Location } from "react-router-dom";
import loader from "../../../assets/Screenloader.svg";
import { MobileData } from "../NewMobiles/interface";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import { setFilteredMobiles } from "../../../store/slice/mobilesSlice";
import TopFilters from "./TopFilters";
import LeftFilters from "./LeftFilters";
import { Pagination } from "antd";
import { Helmet } from "react-helmet";
import slugs from "../../../config/slugConfig";
// import { setTimeout } from "timers/promises";

// import NewMobiles1 from "../NewMobile1/NewMobile1";
// import NewMobileCardGrid from "../NewMobile1/NewMobileCardGrid";
// import NewMobileCardList from "../NewMobile1/NewMobileCardList";
import NewMobiles1 from "./UsedMobilesSubComponents/UsedMobile1";
import NewMobileCardGrid from "./UsedMobilesSubComponents/UsedMobileCardGrid";
import NewMobileCardList from "./UsedMobilesSubComponents/UsedMobileCardList";

interface filters {
  brand: string[];
  minPrice: number;
  maxPrice: number;
  recommended: boolean;
  screenSize: string[];
  ram: number[];
  storage: number[];
  sort: string;
}
interface featuredData {
  featuredArray: MobileData[];
  expensiveArray: MobileData[];
  highRangeArray: MobileData[];
  midRangeArray: MobileData[];
  midLowRangeArray: MobileData[];
  lowRangeArray: MobileData[];
}

const initialFilter: filters = {
  brand: [],
  minPrice: 0,
  maxPrice: 1000000,
  recommended: false,
  screenSize: [],
  ram: [],
  storage: [],
  sort: "",
};
const initialfeaturedData: featuredData = {
  featuredArray: [],
  expensiveArray: [],
  highRangeArray: [],
  midRangeArray: [],
  midLowRangeArray: [],
  lowRangeArray: [],
};

const ITEMS_PER_PAGE = 18;

function levenshteinDistance(s: any, t: any) {
  // Create an array to hold the distances
  const d = [];

  // Initialize the first row and column of the array
  for (let i = 0; i <= s.length; i++) {
    d[i] = [i];
  }
  for (let j = 0; j <= t.length; j++) {
    d[0][j] = j;
  }

  // Populate the rest of the array with the distances
  for (let i = 1; i <= s.length; i++) {
    for (let j = 1; j <= t.length; j++) {
      const cost = s[i - 1] === t[j - 1] ? 0 : 1;
      d[i][j] = Math.min(
        d[i - 1][j] + 1,
        d[i][j - 1] + 1,
        d[i - 1][j - 1] + cost
      );
    }
  }

  // The distance is in the bottom-right corner of the array
  return d[s.length][t.length];
}

function similarity(s1: any, s2: any) {
  const longer = s1.length > s2.length ? s1 : s2;
  const shorter = s1.length > s2.length ? s2 : s1;
  const longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0; // two empty strings are considered 100% similar
  }
  const editDistance = levenshteinDistance(longer, shorter);
  return (longerLength - editDistance) / parseFloat(longerLength);
}

const UsedMobiles: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mobilesData: MobileData[] = useSelector(
    (state: RootState) => state.mobiles.mobiles
  );
  const filteredMobilesData = useSelector(
    (state: RootState) => state.mobiles.filteredMobiles
  );

  const [featuredDocuments, setFeaturedDocuments] =
    useState(initialfeaturedData);
  const [viewMode, setViewMode] = useState<string>("grid");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<filters>(initialFilter);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [showFiltersSidebar, setShowFiltersSidebar] = useState(false);
  const [isFeaturedTag, setIsFeaturedTag] = useState(false);
  const paginatedMobiles = filteredMobilesData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  };
  const applyFilters = (filterName: string, value: any) => {
    switch (filterName) {
      case "brand":
        const updatedFilters = { ...filters };
        const brandIndex = updatedFilters.brand.indexOf(value);
        if (brandIndex === -1) {
          updatedFilters.brand.push(value);
        } else {
          updatedFilters.brand.splice(brandIndex, 1);
        }
        setFilters(updatedFilters);
        break;
      case "minPrice":
        setFilters({ ...filters, minPrice: value });
        break;
      case "maxPrice":
        setFilters({ ...filters, maxPrice: value });
        break;
      case "maxAndMaxPrice":
        setFilters({ ...filters, minPrice: value.min, maxPrice: value.max });
        break;
      case "recommended":
        const tempRecom = filters;
        tempRecom.recommended = value;

        setFilters(tempRecom);
        setIsFeaturedTag(tempRecom.recommended);
        break;
      case "screenSize":
        const updatedScreenSize = { ...filters };
        const screenIndex = updatedScreenSize.screenSize.indexOf(value);
        if (screenIndex === -1) {
          updatedScreenSize.screenSize.push(value);
        } else {
          updatedScreenSize.screenSize.splice(screenIndex, 1);
        }
        setFilters(updatedScreenSize);
        break;
      case "ram":
        const updatedFiltersRam = { ...filters };
        if (value == 2) {
          const ramIndex = updatedFiltersRam.ram.indexOf(value);
          if (ramIndex === -1) {
            updatedFiltersRam.ram.push(value);
            updatedFiltersRam.ram.push(1);
          } else {
            const ramIndexof1 = updatedFiltersRam.ram.indexOf(1);
            updatedFiltersRam.ram.splice(ramIndex, 1);
            updatedFiltersRam.ram.splice(ramIndexof1, 1);
          }
          setFilters(updatedFiltersRam);
          break;
        }
        // if (value == 8) {
        //   const ramIndex = updatedFiltersRam.ram.indexOf(value);
        //   if (ramIndex === -1) {
        //     updatedFiltersRam.ram.push(value);
        //     updatedFiltersRam.ram.push(12);
        //   } else {
        //     const ramIndexof12 = updatedFiltersRam.ram.indexOf(12);
        //     updatedFiltersRam.ram.splice(ramIndex, 1);
        //     updatedFiltersRam.ram.splice(ramIndexof12, 1);
        //   }
        //   setFilters(updatedFiltersRam);
        //   break;
        // }
        if (value == 12) {
          const ramIndex = updatedFiltersRam.ram.indexOf(value);
          if (ramIndex === -1) {
            updatedFiltersRam.ram.push(value);
            updatedFiltersRam.ram.push(16);
          } else {
            const ramIndexof12 = updatedFiltersRam.ram.indexOf(16);
            updatedFiltersRam.ram.splice(ramIndex, 1);
            updatedFiltersRam.ram.splice(ramIndexof12, 1);
          }
          setFilters(updatedFiltersRam);
          break;
        }
        const ramIndex = updatedFiltersRam.ram.indexOf(value);
        if (ramIndex === -1) {
          updatedFiltersRam.ram.push(value);
        } else {
          updatedFiltersRam.ram.splice(ramIndex, 1);
        }
        setFilters(updatedFiltersRam);
        break;

      case "storage":
        const updatedFiltersStorage = { ...filters };
        const storageIndex = updatedFiltersStorage.storage.indexOf(value);
        if (storageIndex === -1) {
          updatedFiltersStorage.storage.push(value);
        } else {
          updatedFiltersStorage.storage.splice(storageIndex, 1);
        }
        setFilters(updatedFiltersStorage);
        break;
      case "sort":
        let tempSort = { ...filters };
        tempSort.sort = value;
        setFilters(tempSort);
        break;

      default:
        break;
    }
    updateURL();
    setFiltersApplied(true);
  };

  const currency = useSelector((state: RootState) => state.country.currency);
  useEffect(() => {
    if (currency && currency != "RS") {
      // console.log(currency);
      setFiltersApplied(true);
      // if no filters applied then apply sort

      const queryParams = new URLSearchParams();
      if (queryParams.size == 0) {
        applyFilters("sort", "date_desc");
      }
    }
  }, [currency]);

  const updateURL = () => {
    const queryParams = new URLSearchParams();

    // Coming from home with brand and reset
    if (searchParams.get("reset") == "reset") {
      const brandValue = searchParams.get("brand") || "";
      if (!brandValue) {
        resetFilters();
      } else {
        dispatch(setFilteredMobiles(mobilesData));
      }
      const updatedFilters = { ...initialFilter };
      updatedFilters.brand = [];
      updatedFilters.ram = [];
      updatedFilters.screenSize = [];
      updatedFilters.storage = [];
      if (brandValue) {
        updatedFilters.brand.push(brandValue);
      }
      updatedFilters.sort = "desc";
      setFilters(updatedFilters);

      if (currency && currency !== "RS") {
        setFiltersApplied(true);
      }
    } else {
      const uniqueBrands = [...new Set(filters.brand)];
      if (uniqueBrands.length > 0) {
        queryParams.append("brand", uniqueBrands.join(","));
      }
      if (filters.minPrice > 0) {
        queryParams.append("minPrice", filters.minPrice.toString());
      }
      if (filters.maxPrice < 1000000) {
        queryParams.append("maxPrice", filters.maxPrice.toString());
      }
      if (filters.recommended) {
        queryParams.append("recommended", "true");
      }

      const uniqueScreenSize = [...new Set(filters.screenSize)];
      if (uniqueScreenSize.length > 0) {
        queryParams.append("screenSize", uniqueScreenSize.join(","));
      }
      const uniqueRam = [...new Set(filters.ram)];
      if (uniqueRam.length > 0) {
        queryParams.append("ram", uniqueRam.join(","));
      }
      const uniqueStorage = [...new Set(filters.storage)];
      if (uniqueStorage.length > 0) {
        queryParams.append("storage", uniqueStorage.join(","));
      }
      if (filters.sort !== "") {
        queryParams.append("sort", filters.sort);
      } else {
        // By default, sort is set to desc
        queryParams.append("sort", "desc");
      }
    }
    if (searchParams.get("search")) {
      queryParams.append("search", searchParams.get("search") as string);
    }

    const newURL = `/${slugs.used_mobiles}?${queryParams.toString()}`;
    navigate(newURL);
  };

  const ReadMobileData = () => {
    const array: MobileData[] = [];
    const featured: featuredData = initialfeaturedData;
    mobilesData.forEach((data: MobileData) => {
      if (featured.featuredArray.length < 4 && data.featured == "Featured") {
        featured.featuredArray.push(data);
      }
      if (
        featured.expensiveArray.length < 4 &&
        data.variants[0].priceInPkr > 50000
      ) {
        featured.expensiveArray.push(data);
      }
      if (
        featured.highRangeArray.length < 4 &&
        data.variants[0].priceInPkr >= 40000 &&
        data.variants[0].priceInPkr <= 50000
      ) {
        featured.highRangeArray.push(data);
      }
      if (
        featured.midRangeArray.length < 4 &&
        data.variants[0].priceInPkr >= 30000 &&
        data.variants[0].priceInPkr <= 40000
      ) {
        featured.midRangeArray.push(data);
      }
      if (
        featured.midLowRangeArray.length < 4 &&
        data.variants[0].priceInPkr >= 20000 &&
        data.variants[0].priceInPkr <= 30000
      ) {
        featured.midLowRangeArray.push(data);
      }
      if (
        featured.lowRangeArray.length < 4 &&
        data.variants[0].priceInPkr >= 10000 &&
        data.variants[0].priceInPkr <= 20000
      ) {
        featured.lowRangeArray.push(data);
      }
      array.push(data);
    });
    setTotalItems(array.length);
    setFeaturedDocuments(featured);
    applyRemainingFilters(array);
    setLoading(false);
  };

  const applyRemainingFilters = (documentsArray: MobileData[]) => {
    updateURL();
    const array = documentsArray.filter((data) => {
      // Brand filter
      if (filters.brand.length > 0 && !filters.brand.includes(data.brandName)) {
        return false;
      }

      // RAM filter
      if (
        filters.ram.length > 0 &&
        !data.variants.some((variant) => filters.ram.includes(variant.ram))
      ) {
        return false;
      }

      // Storage filter
      if (
        filters.storage.length > 0 &&
        !data.variants.some((variant) =>
          filters.storage.includes(variant.internalStorage)
        )
      ) {
        return false;
      }

      // Min price filter
      if (
        filters.minPrice > 0 &&
        !data.variants.some(
          (variant) => variant?.priceInPkr >= filters.minPrice
        )
      ) {
        return false;
      }

      // Max price filter
      if (
        filters.maxPrice < 1000000 &&
        !data.variants.some(
          (variant) => variant?.priceInPkr <= filters.maxPrice
        )
      ) {
        return false;
      }

      // Screen size filter
      if (filters.screenSize.length > 0) {
        const sizeInInch = parseFloat(data.size.slice(0, -7).trim());
        const screenFound = filters.screenSize.some((size) => {
          switch (size) {
            case "4.0_to_below":
              return sizeInInch <= 4;
            case "4.0_to_4.5":
              return sizeInInch >= 4 && sizeInInch <= 4.5;
            case "5.0_to_5.5":
              return sizeInInch >= 5 && sizeInInch <= 5.5;
            case "5.6_to_6.9":
              return sizeInInch >= 5.6 && sizeInInch <= 6.9;
            case "7.0_to_above":
              return sizeInInch >= 7;
            default:
              return false;
          }
        });
        if (!screenFound) return false;
      }
      return true;
    });

    // Sorting logic
    if (filters.sort === "asc" || filters.sort === "desc") {
      const sortedData = array
        .map((mobileData) => ({
          ...mobileData,
          firstVariantPrice:
            mobileData.variants.length > 0
              ? mobileData.variants[0].priceInCurrency
              : 0,
        }))
        .sort((a, b) => {
          return filters.sort === "asc"
            ? a.firstVariantPrice - b.firstVariantPrice
            : b.firstVariantPrice - a.firstVariantPrice;
        });

      dispatch(setFilteredMobiles(sortedData));
    } else if (filters.sort === "ram_asc") {
      const sortedData = array.sort((a, b) => {
        const minRamA = Math.min(...a.variants.map((v) => v.ram));
        const minRamB = Math.min(...b.variants.map((v) => v.ram));

        return minRamA - minRamB;
      });

      dispatch(setFilteredMobiles(sortedData));
    } else if (filters.sort === "ram_desc") {
      const sortedData = array.sort((a, b) => {
        const maxRamA = Math.max(...a.variants.map((v) => v.ram));
        const maxRamB = Math.max(...b.variants.map((v) => v.ram));

        return maxRamB - maxRamA;
      });

      dispatch(setFilteredMobiles(sortedData));
    } else if (filters.sort === "rom_asc") {
      const sortedData = array.sort((a, b) => {
        const minRomA = Math.min(...a.variants.map((v) => v.internalStorage));
        const minRomB = Math.min(...b.variants.map((v) => v.internalStorage));

        return minRomA - minRomB;
      });

      dispatch(setFilteredMobiles(sortedData));
    } else if (filters.sort === "rom_desc") {
      const sortedData = array.sort((a, b) => {
        const maxRomA = Math.max(...a.variants.map((v) => v.internalStorage));
        const maxRomB = Math.max(...b.variants.map((v) => v.internalStorage));

        return maxRomB - maxRomA;
      });

      dispatch(setFilteredMobiles(sortedData));
    } else if (filters.sort === "date_asc" || filters.sort === "date_desc") {
      const sortedData = array.sort((a, b) => {
        // Convert a's dateCreated to a Date object and then to a timestamp
        const dateATimestamp =
          typeof a.dateCreated === "string"
            ? new Date(a.dateCreated).getTime()
            : new Date(a.dateCreated._seconds * 1000).getTime();

        // Convert b's dateCreated to a Date object and then to a timestamp
        const dateBTimestamp =
          typeof b.dateCreated === "string"
            ? new Date(b.dateCreated).getTime()
            : new Date(b.dateCreated._seconds * 1000).getTime();

        return filters.sort === "date_asc"
          ? dateATimestamp - dateBTimestamp
          : dateBTimestamp - dateATimestamp;
      });

      dispatch(setFilteredMobiles(sortedData));
    } else {
      dispatch(setFilteredMobiles(array));
    }
  };

  useEffect(() => {
    let filtersData = filters;

    if (searchParams.get("brand")) {
      let brands = [];
      brands = searchParams.get("brand")?.split(",") ?? [];
      if (brands.length > 0) {
        filtersData.brand.push(...brands);
      }
    }
    if (searchParams.get("minPrice")) {
      filtersData.minPrice = parseInt(searchParams.get("minPrice") ?? "0") ?? 0;
    }
    if (searchParams.get("maxPrice")) {
      filtersData.maxPrice =
        parseInt(searchParams.get("maxPrice") ?? "1000000") ?? 1000000;
    }
    if (searchParams.get("recommended")) {
      filtersData.recommended = searchParams.get("recommended") ? true : false;
    }
    if (searchParams.get("screenSize")) {
      let screenSizes = [];
      screenSizes = searchParams.get("screenSize")?.split(",") ?? [];
      if (screenSizes.length > 0) {
        filtersData.brand.push(...screenSizes);
      }
    }
    if (searchParams.get("ram")) {
      let rams = [];
      rams = searchParams.get("ram")?.split(",") ?? [];
      if (rams.length > 0) {
        let nrams = rams.map((ram) => parseInt(ram));
        filtersData.ram.push(...nrams);
      }
    }
    if (searchParams.get("storage")) {
      let storage = [];
      storage = searchParams.get("storage")?.split(",") ?? [];
      if (storage.length > 0) {
        let nStorage = storage.map((storage) => parseInt(storage));
        filtersData.storage.push(...nStorage);
      }
    }
    if (JSON.stringify(filters) !== JSON.stringify(filtersData)) {
      setFilters(filtersData);
      setFiltersApplied(true);
    }
    if (!loading) {
      ReadMobileData();
      setLoading(true);
    }
  }, []);
  useEffect(() => {
    if (
      searchParams.size > 1 &&
      searchParams.get("sort") &&
      filters.sort == ""
    ) {
      applyFilters("sort", searchParams.get("sort"));
    }
    // if (
    //   searchParams.size == 1 &&
    //   searchParams.get("sort") &&
    //   currency &&
    //   currency == "RS" &&
    //   filtersApplied == true
    // ) {
    //   setFiltersApplied(false);
    // }
    if (
      searchParams.get("brand") ||
      searchParams.get("minPrice") ||
      searchParams.get("maxPrice")
    ) {
      setFiltersApplied(true);
    }
    ReadMobileData();
  }, [filters]);

  useEffect(() => {
    ReadMobileData();
  }, [mobilesData]);

  const paginationButtonStyles = {
    backgroundColor: "#F8F9FA",
    color: "#9D4EDD",
    borderRadius: "7px",
    margin: "0px 3px",
    padding: "0px 10px",
    border: "none",
  };

  const generateSEOTitle = () => {
    let titleParts = ["Explore Mobiles"];

    if (filters.brand.length > 0) {
      titleParts.push(`Brands: ${filters.brand.join(", ")}`);
    }
    if (filters.minPrice > 0 || filters.maxPrice < 1000000) {
      titleParts.push(`Price: $${filters.minPrice} - $${filters.maxPrice}`);
    }
    if (filters.screenSize.length > 0) {
      titleParts.push(`Screen Size: ${filters.screenSize.join(", ")}`);
    }
    if (filters.ram.length > 0) {
      titleParts.push(`RAM: ${filters.ram.join(", ")} GB`);
    }
    if (filters.storage.length > 0) {
      titleParts.push(`Storage: ${filters.storage.join(", ")} GB`);
    }

    return titleParts.join(" | ");
  };

  const generateSEODescription = () => {
    let descriptionParts = [
      "Find the best mobiles according to your preferences.",
    ];

    if (filters.brand.length > 0) {
      descriptionParts.push(
        `Choose from brands like ${filters.brand.join(", ")}.`
      );
    }
    if (filters.minPrice > 0 || filters.maxPrice < 1000000) {
      descriptionParts.push(
        `Select within your budget from $${filters.minPrice} to $${filters.maxPrice}.`
      );
    }
    if (filters.screenSize.length > 0) {
      descriptionParts.push(
        `Screen sizes available: ${filters.screenSize.join(", ")}.`
      );
    }
    if (filters.ram.length > 0) {
      descriptionParts.push(`Options with RAM: ${filters.ram.join(", ")} GB.`);
    }
    if (filters.storage.length > 0) {
      descriptionParts.push(
        `Models with storage capacities: ${filters.storage.join(", ")} GB.`
      );
    }

    return descriptionParts.join(" ");
  };

  // useEffect(() => {}, [filters]);

  useEffect(() => {
    let filteredMobiles = mobilesData;

    if (searchParams.get("brand")) {
      const brandParam = searchParams.get("brand");
      if (brandParam !== null) {
        const brands = brandParam.split(",");
        filteredMobiles = filteredMobiles.filter((m) => {
          return brands.includes(m.brandName);
        });
      }
    }

    // // Apply search filter
    // const searchQuery = searchParams.get("search");
    // if (searchQuery) {
    //   filteredMobiles = filteredMobiles.filter((mobile) =>
    //     mobile.modelName.toLowerCase().includes(searchQuery.toLowerCase())
    //   );
    //   setFiltersApplied(true);
    // }

    // // const searchQuery = searchParams.get("search");
    // if (filteredMobiles.length == 0) {
    //   filteredMobiles = mobilesData.filter((mobile: any) => {
    //     return similarity(mobile.modelName, searchQuery) >= 0.15;
    //   });
    //   setFiltersApplied(true);
    // }

    const searchQuery = searchParams.get("search");

    if (searchQuery) {
      filteredMobiles = filteredMobiles.filter((mobile) =>
        mobile.modelName.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFiltersApplied(true);
    }
    if (searchQuery && filteredMobiles.length == 0) {
      // if (searchQuery) {
      // Calculate similarity for each phone model
      const scoredMobiles = mobilesData.map((mobile) => {
        const score =
          mobile.modelName.toLowerCase() === searchQuery.toLowerCase()
            ? 1 // Exact match
            : similarity(mobile.modelName, searchQuery);
        // console.log(score, mobile.modelName);
        return { ...mobile, score };
      });

      // Filter out phones with a score below a certain threshold to remove irrelevant results
      const relevantMobiles = scoredMobiles.filter(
        (mobile) => mobile.score >= 0.2
      );

      // Sort the relevant phones by score in descending order
      const sortedMobiles = relevantMobiles.sort((a, b) => b.score - a.score);

      filteredMobiles = sortedMobiles;
      setFiltersApplied(true);
    }

    // Apply recommended filter
    if (filters.recommended) {
      filteredMobiles = filteredMobiles.filter(
        (mobile) => mobile.featured === "Featured"
      );
    }

    applyRemainingFilters(filteredMobiles);
  }, [
    searchParams.get("search"),
    mobilesData,
    filters.recommended,
    dispatch,
    location.search,
  ]);

  const prevLocationRef = useRef(location.pathname);

  useEffect(() => {
    const prevPathname = prevLocationRef.current;
    const currentPathname = location.pathname;

    // Check if we're navigating away from the specific page
    if (
      prevPathname.startsWith("/Mobiles") &&
      currentPathname.startsWith(slugs.used_mobiles)
    ) {
      resetFilters();
    }
    prevLocationRef.current = location.pathname;
  }, [location]);

  const resetFilters = () => {
    setFiltersApplied(false);
    setFilters({
      brand: [],
      minPrice: 0,
      maxPrice: 1000000,
      recommended: false,
      screenSize: [],
      ram: [],
      storage: [],
      sort: "",
    });
    dispatch(setFilteredMobiles(mobilesData));
  };

  return (
    <div className="used-mobiles-parent-class">
      <div className="newMobile" key={location.pathname}>
        <Helmet>
          {/* <title>{generateSEOTitle()}</title>
        <meta name="description" content={generateSEODescription()} /> */}
          <title>Mobiles Catalog: Find Your Perfect Device - Ibad Mobile</title>
          <meta
            name="description"
            content="Explore our Mobiles Catalog for the latest smartphones categorized by brand, price, and specs. Find your ideal device on Ibad Mobile."
          />
          <meta name="keywords" content="Mobiles Catalog" />
          <meta
            name="google-adsense-account"
            content="ca-pub-3818311597459709"
          ></meta>
        </Helmet>
        <div className="container">
          <div className="row my-4">
            <div className="col">
              <nav aria-label="breadcrumb ">
                <ol className="breadcrumb mb-0">
                  <li
                    className="breadcrumb-item"
                    style={{ fontSize: "12px", fontWeight: "300" }}
                  >
                    <Link className="text-dark" to="/">
                      Home
                    </Link>
                  </li>
                  <li
                    className={`breadcrumb-item active ${
                      isFeaturedTag ? "fw-light" : "fw-normal"
                    }`}
                    aria-current="page"
                    style={{
                      fontSize: "12px",
                      minWidth: "122px",
                    }}
                    onClick={() => {
                      setIsFeaturedTag(false);
                    }}
                  >
                    <Link
                      className={`${isFeaturedTag ? "text-dark" : "text-info"}`}
                      to={`/${slugs.used_mobiles}?reset=reset`}
                    >
                      Used Mobiles
                    </Link>
                  </li>
                  {isFeaturedTag && (
                    <li
                      className={`breadcrumb-item active text-info ${
                        isFeaturedTag ? "fw-normal" : "fw-light"
                      }`}
                      aria-current="page"
                      style={{ fontSize: "12px" }}
                    >
                      Featured
                    </li>
                  )}
                </ol>
              </nav>
              <h5 style={{ fontSize: "24px", fontWeight: "800" }}>
                Used Mobiles In Pakistan
              </h5>
              <p style={{ fontSize: "14px", fontWeight: "300" }}>
                {totalItems} items found
              </p>
            </div>
          </div>
          <div className="row">
            <LeftFilters
              filters={filters}
              applyFilters={applyFilters}
              showFiltersSidebar={showFiltersSidebar}
              setShowFiltersSidebar={setShowFiltersSidebar}
              filtersApplied={filtersApplied}
            />
            <div className="col col-md-8 col-lg-9">
              <TopFilters
                filters={filters}
                applyFilters={applyFilters}
                setFiltersApplied={setFiltersApplied}
                viewMode={viewMode}
                setViewMode={setViewMode}
                documents={mobilesData}
                showFiltersSidebar={showFiltersSidebar}
                setShowFiltersSidebar={setShowFiltersSidebar}
                resetFilters={resetFilters}
              />
              {loading ? (
                <div className="col">
                  <div className="loading d-flex justify-content-center ">
                    <img
                      src={loader}
                      alt=""
                      style={{ mixBlendMode: "multiply" }}
                    />
                  </div>
                </div>
              ) : (
                <>
                  {/* {filtersApplied ? (
                    filteredMobilesData.length > 0 ? ( */}
                      <div className="row">
                        <div className="row mt-4" id="paginatedMobiles">
                          {paginatedMobiles.map((mobiles, i) => (
                            <div
                              className={` ${
                                viewMode === "grid"
                                  ? "col-6 col-md-4 col-lg-3"
                                  : "col-12"
                              } mb-3`}
                              key={i}
                            >
                              <Link
                                to={`/used-mobile-details/${mobiles.id}/${mobiles.modelName}`}
                              >
                                {viewMode === "grid" ? (
                                  <NewMobileCardGrid mobile={mobiles} />
                                ) : (
                                  <NewMobileCardList mobile={mobiles} />
                                )}
                              </Link>
                            </div>
                          ))}
                        </div>
                        <div className="row mb-5 mt-2">
                          <div className="col d-flex justify-content-end">
                            <Pagination
                              current={currentPage}
                              pageSize={ITEMS_PER_PAGE}
                              total={filteredMobilesData.length}
                              onChange={handlePageChange}
                              itemRender={(page, type, originalElement) => {
                                if (
                                  type === "prev" ||
                                  type === "next" ||
                                  type === "jump-prev" ||
                                  type === "jump-next"
                                ) {
                                  return originalElement;
                                }
                                return (
                                  <button
                                    style={paginationButtonStyles}
                                    className="ant-pagination-item-link"
                                  >
                                    {page}
                                  </button>
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    {/* ) : (
                      <div className="row">
                        <p className="py-5">No mobile matches your search</p>
                      </div>
                    )
                  ) : (
                    <NewMobiles1
                      viewMode={viewMode}
                      featuredData={featuredDocuments}
                      setViewMode={setViewMode}
                      applyFilters={applyFilters}
                    />
                  )} */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsedMobiles;
