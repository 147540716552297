import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Overlay from "../SignInPage/Overlay";
import Home from "../Home/Home";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  auth,
  googleProvider,
  signInWithPopup,
  db,
} from "../../../config/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useAuth } from "../../../context/AuthContext";
import { setDoc, doc } from "firebase/firestore";

function SignUpPage() {
  const [showOverlay, setShowOverlay] = useState(true);
  const [fullName, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { dispatch } = useAuth();

  const handleClose = () => {
    setShowOverlay(false);
    navigate("/"); // Navigate to home page
  };

  // const handleSignUp = async (e: any) => {
  //   e.preventDefault();
  //   setError(null); // Clear any previous errors

  //   // Basic validation
  //   if (!fullName) {
  //     setError("Full Name is required");
  //     return;
  //   }

  //   if (!mobileNumber || !/^\d{11}$/.test(mobileNumber)) {
  //     setError("Please enter a valid 11-digit mobile number");
  //     return;
  //   }

  //   if (!agreeTerms) {
  //     setError("You must agree to continue.");
  //     return;
  //   }

  //   try {
  //     const userCredential = await createUserWithEmailAndPassword(
  //       auth,
  //       email,
  //       password
  //     );
  //     const user = userCredential.user;

  //     // Save additional user info to Firestore
  //     await setDoc(doc(db, "users", user.uid), {
  //       fullName,
  //       mobileNumber,
  //       email,
  //     });

  //     console.log("Full User Details:", {
  //       fullName,
  //       mobileNumber,
  //       email,
  //     });

  //     console.log("User signed up: ", user);
  //     dispatch({ type: "LOGIN", payload: { user } });

  //     navigate("/");
  //   } catch (error: any) {
  //     console.error("Error during sign-up: ", error);
  //     setError(error.message);
  //   }
  // };

  const handleSignUp = async (e: any) => {
    e.preventDefault();
    setError(null); // Clear any previous errors

    // Basic validation
    if (!fullName) {
      setError("Full Name is required");
      return;
    }

    if (!mobileNumber || !/^\d{11}$/.test(mobileNumber)) {
      setError("Please enter a valid 11-digit mobile number");
      return;
    }

    if (!agreeTerms) {
      setError("You must agree to continue.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Save additional user info to Firestore
      await setDoc(doc(db, "users", user.uid), {
        fullName,
        mobileNumber,
        email,
      });

      console.log("Full User Details:", {
        fullName,
        mobileNumber,
        email,
      });

      console.log("User signed up: ", user);

      // Dispatch login action with additional user details
      dispatch({
        type: "LOGIN",
        payload: {
          user: {
            ...user,
            fullName,
            mobileNumber,
          },
        },
      });

      navigate("/");
    } catch (error: any) {
      console.error("Error during sign-up: ", error);
      setError(error.message);
    }
  };

  const handleGoogleSignUp = async () => {
    setError(null);
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log("User signed up with Google: ", result.user);
      dispatch({ type: "LOGIN", payload: { user: result.user } });

      navigate("/");
    } catch (error: any) {
      console.error("Error during Google sign-up: ", error);
      setError(error.message);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <Home />
      {showOverlay && (
        <Overlay onClose={handleClose}>
          <div className="signup-form">
            <div className="text-center mb-4">
              <h2>Sign Up</h2>
              <p>Create an account to continue</p>
            </div>
            <form onSubmit={handleSignUp}>
              <div className="form-group">
                <label>Full Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your full name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Mobile Number</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your mobile number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <div
                    className="input-group-append"
                    onClick={togglePasswordVisibility}
                    style={{ cursor: "pointer" }}
                  >
                    <span className="input-group-text">
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group d-flex align-items-center">
                <input
                  type="checkbox"
                  id="agree-terms"
                  checked={agreeTerms}
                  onChange={(e) => setAgreeTerms(e.target.checked)}
                />
                <label htmlFor="agree-terms" className="ml-2">
                  I agree to the processing of{" "}
                  <a href="#" style={{ display: "inline-block" }}>
                    Personal data
                  </a>
                </label>
              </div>
              {error && <div className="text-danger">{error}</div>}
              <button type="submit" className="btn btn-primary btn-block">
                Sign up
              </button>
              {/* <div className="text-center mt-2">
                <span>or</span>
              </div> */}
              <button
                type="button"
                className="btn btn-secondary btn-block text-cotinue"
                onClick={handleGoogleSignUp}
              >
                Continue With Google
              </button>
              <div className="text-center mt-3 d-flex justify-content-center">
                <p className="mx-1">Already have an account?</p>{" "}
                <a href="/sign-in">Sign in</a>
              </div>
            </form>
          </div>
        </Overlay>
      )}
    </>
  );
}

export default SignUpPage;
