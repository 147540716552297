import "./mobile1.scss";
import React, { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import { MobileData } from "../NewMobiles/interface";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

interface NewMobileCardGridProps {
  mobile: MobileData; // Define the prop to accept mobile data
}
const NewMobileCardGrid: React.FC<NewMobileCardGridProps> = ({ mobile }) => {
  const [rating, setRating] = useState<any>(0);
  const handleRating = (rate: number) => {
    setRating(rate);
  };
  const onPointerEnter = () => {};
  const onPointerLeave = () => {};
  const onPointerMove = (value: number, index: number) => {};
  // const onPointerEnter = () => console.log("Enter");
  // const onPointerLeave = () => console.log("Leave");
  // const onPointerMove = (value: number, index: number) =>
  //   console.log(value, index);

  const currency = useSelector((state: RootState) => state.country.currency);
  const mobiles = useSelector((state: RootState) => state.mobiles.mobiles);
  const mobileDetail = mobiles.find((m) => m.id === mobile.id); // Filter the specific mobile based on id

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [selectedVariant, setSelectedVariant] = useState(null);
  useEffect(() => {
    const minPrice = Number(searchParams.get("minPrice")) || 0;
    const maxPrice = Number(searchParams.get("maxPrice")) || 1000000;

    const foundVariant = mobileDetail.variants.find((variant: any) => {
      const price = parseFloat(variant.priceInCurrency);
      return price >= minPrice && price <= maxPrice;
    });

    setSelectedVariant(foundVariant);
  }, [searchParams]);

  const getRating = (mData: any) => {
    const Added =
      mData.audioRating / 200 +
      mData.batteryRating / 200 +
      mData.bluetoothRating / 200 +
      mData.chargingRating / 200 +
      mData.chipsetRating / 200 +
      mData.cpuRating / 200 +
      mData.displayTypeRating / 200 +
      mData.frontCameraRating / 200 +
      mData.gpuRating / 200 +
      mData.internalStorageRating / 200 +
      mData.mainCameraFeaturesRating / 200 +
      mData.mainCameraRating / 200 +
      mData.networkRating / 200 +
      mData.priceRating / 200 +
      mData.radioRating / 200 +
      mData.resolutionRating / 200 +
      mData.sensorsRating / 200 +
      mData.simRating / 200 +
      mData.sizeRating / 200 +
      mData.usbRating / 200 +
      mData.weightRating / 200 +
      mData.wifiTechnologyRating / 200;
    const result = Added / 22;
    return result < 2 ? 3 : result < 3 && result > 2 ? 4 : result;
  };
  return (
    <div className="mobile-card card grid__card">
      <div className="mt-md-1  d-flex justify-content-center align-items-center py-3 pt-md-2 pb-md-0 overflow-hidden ">
        <img
          src={mobileDetail.imageUrls[0]}
          style={{ mixBlendMode: "multiply" }}
          height="114px"
          alt="mobile"
        />
      </div>
      <div className="card-body">
        <h6 style={{ fontWeight: "400" }}>
          {mobileDetail.modelName}
          {/* {mobileDetail.brandName} <span>|</span> {mobileDetail.modelName} */}
        </h6>
        <div
          className="price_rating"
          // style={{ position: "absolute", bottom: "22px" }}
        >
          <h5 style={{ fontWeight: "500" }} className="price mb-0">
            {currency}{" "}
            {/* {mobileDetail.variants[0] &&
              mobileDetail.variants[0].priceInCurrency} */}
            {/* {selectedVariant && selectedVariant.priceInCurrency} */}
            {selectedVariant
              ? /* @ts-ignore  */
                selectedVariant.priceInCurrency
              : mobileDetail.variants[0] &&
                mobileDetail.variants[0].priceInCurrency}
          </h5>
          <div className="rating d-flex ">
            <Rating
              onClick={handleRating}
              onPointerEnter={onPointerEnter}
              onPointerLeave={onPointerLeave}
              onPointerMove={onPointerMove}
              size={16}
              className="rating"
              initialValue={getRating(mobile)}
            />
            <p
              className="reviews ms-2"
              style={{
                fontSize: "14px",
                fontWeight: "300",
                marginTop: "5px",
                marginBottom: "0px",
                minWidth: "100px",
              }}
            >
              {mobile.mainCameraRating} reviews
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewMobileCardGrid;
