import React, { useEffect, useState } from "react";
import "./banner.scss";
import img from "../../assets/bannerimg.png";
import SearchIcon from "@mui/icons-material/Search";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
// import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import { useNavigate } from "react-router-dom";
import { MobileData } from "../FeaturedMobile/interface2";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import slugs from "../../config/slugConfig";

const Banner: React.FC = () => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [showAllSuggestions, setShowAllSuggestions] = useState(false);
  const [selectedValue, setSelectedValue] = useState("5000,20000"); // Set the initial value
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [mobileId, setMobileId] = useState<string[]>([]);
  const navigate = useNavigate();
  const mobileData: MobileData[] = useSelector(
    (state: RootState) => state.mobiles.mobiles
  );

  const handleSelectChange = (event: any) => {
    // console.log(event.target.value);
    setSelectedValue(event.target.value); // Update the selected value when the select input changes
  };

  const seachOnPrice = () => {
    // console.log(selectedValue);
    const values = selectedValue.split(",");
    navigate(`/${slugs.new_mobiles}?minPrice=${values[0]}&maxPrice=${values[1]}`);
  };

  const handleSearchInputChange = (query: any) => {
    setSearchQuery(query);
    // const filteredMobiles = mobileData
    //   .map((mobile) => ({
    //     modelName: mobile.modelName,
    //     imageUrls: mobile.imageUrls,
    //     id: mobile.id,
    //   }))
    //   .filter((mobile) =>
    //     mobile.modelName.toLowerCase().includes(query.toLowerCase())
    //   );

    const filteredMobiles = mobileData.filter((mobile: MobileData) =>
      mobile.modelName.toLowerCase().includes(query.toLowerCase())
    );

    const modelNames = filteredMobiles.map((mobile) => mobile.modelName);
    const mobileIds = filteredMobiles.map((mobile) => mobile.id);
    const imageUrls = filteredMobiles.flatMap((mobile) => mobile.imageUrls); // Use flatMap to flatten the array

    // const uniqueSuggestions = Array.from(new Set(modelNames)).slice(0, 4);
    setImageUrls(imageUrls);
    setMobileId(mobileIds);
    setSuggestions(modelNames);
    setShowAllSuggestions(query.length > 0);
  };

  const handleShowAllResults = () => {
    const allModelNames = Array.from(
      new Set(mobileData.map((mobile) => mobile.modelName))
    );
    setSuggestions(allModelNames.slice(0, 10)); // Show the first 10 suggestions
    setShowAllSuggestions(true);
  };

  useEffect(() => {
    // ReadMobileData();
    // const retryTimer = setInterval(() => {
    //   if (!documents && fetchAttempts < 3) {
    //     console.log("d", documents);
    //     setFetchAttempts((prevAttempts) => prevAttempts + 1);
    //     ReadMobileData();
    //   } else {
    //     clearInterval(retryTimer);
    //   }
    // }, 5000);

    // Update the window width when it changes
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
      //   clearInterval(retryTimer); // Clean up the timer if the component unmounts
    };
  }, []);

  const handleSearchSubmit = () => {
    // Navigate to the search results page with the search query
    navigate(`/${slugs.new_mobiles}?search=${searchQuery}&reset=reset`);
  };
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const dropdown = document.querySelector(".suggestion-dropdown");
      if (dropdown && !dropdown.contains(event.target)) {
        setShowAllSuggestions(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="container-fluid banner py-5 mb-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center text-white ">
            <div className="text ">
              <h1 className="banner_mainText">Discover, Compare, and Find</h1>
              <h3 className="banner_middleText">Smartly on Our Cutting-Edge</h3>
              <h2 className="banner_endText text-info">Mobile Marketplace</h2>
              <p
                style={{
                  fontSize: 14,
                  fontWeight: "300",
                  fontFamily: "Roboto",
                }}
              >
                Discover your perfect match among our vast selection of
                thousands of mobiles
              </p>
              <form action="">
                <div
                  className="search__Group  py-3 my-4"
                  style={
                    {
                      // borderRadius: "13px",
                      // border: "0.5px solid grey",
                      // display: "flex",
                      // justifyContent: "center",
                      // alignItems: "center",
                    }
                  }
                >
                  <div
                    className="phone__box d-flex align-items-center justify-content-center justify-content-md-start"
                    style={{ gap: "8px", position: "relative" }}
                  >
                    <PhoneAndroidIcon style={{ fontSize: "26px" }} />
                    {/* <p
                        className="search__keywords"
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          marginTop: "3px",
                          marginBottom: 0,
                        }}
                      >
                        Search Mobile
                      </p> */}
                    <input
                      type="search"
                      name=""
                      id="input_search"
                      placeholder="Search Mobile..."
                      onChange={(e) => handleSearchInputChange(e.target.value)}
                      className="form-control"
                      style={{
                        margin: "0px 5px",
                        padding: "10px 10px",
                        height: "30px",
                        borderRadius: "7px",
                        background: " transparent",
                        color: "white",
                        minWidth: "150px",
                        maxWidth: "70%",
                      }}
                    />
                    <button
                      className="btn btn-info"
                      style={{ padding: "0px 3px", borderRadius: "7px" }}
                      onClick={handleSearchSubmit}
                    >
                      <SearchIcon style={{ fontSize: "26px" }} />
                    </button>
                    {showAllSuggestions && suggestions.length > 0 && (
                      <div
                        className="suggestion-dropdown"
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: "8%",
                          width: "100%",
                          maxWidth: "340px",
                          marginTop: "10px",
                          background: "#fff",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          zIndex: 10,
                          cursor: "pointer",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <div
                          style={{
                            maxHeight: "180px",
                            overflowY: "auto",
                            borderBottom: "1px solid #ccc",
                          }}
                        >
                          {suggestions.map((suggestion, index) => {
                            const imageUrl = imageUrls[index];
                            const id = mobileId[index];
                            return (
                              <div
                                key={index}
                                onClick={() =>
                                  navigate(
                                    `/mobile-details/${id}/${suggestion}`
                                  )
                                }
                                className="suggestion-item hover-div"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "5px",
                                }}
                              >
                                <div
                                  style={{
                                    width: "30%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    src={imageUrl}
                                    alt={suggestion}
                                    style={{
                                      maxHeight: "70px",
                                      maxWidth: "60px",
                                      objectFit: "contain",
                                      padding: "0 6px",
                                    }}
                                  />
                                </div>
                                <p
                                  style={{
                                    width: "70%",
                                    paddingLeft: "10px",
                                    color: "black",
                                  }}
                                >
                                  {suggestion}
                                </p>
                              </div>
                            );
                          })}
                        </div>

                        <div
                          className="text-center py-2 bg-info"
                          onClick={handleSearchSubmit}
                        >
                          <span>Show All Results</span>
                        </div>
                        {/* <div className="text-center my-2">
                          <button
                            className="btn btn-info"
                            style={{
                              padding: "0px 3px",
                              borderRadius: "7px",
                            }}
                            onClick={handleSearchSubmit}
                          >
                            Show All Results
                          </button>
                        </div> */}
                      </div>
                    )}
                  </div>
                  {/* <div className='location-box d-flex align-items-center '>
                                        <LocationOnOutlinedIcon style={{ fontSize: '15px' }} className={windowWidth <= 467 ? 'd-none' : 'location_icon'} />
                                        <select className=' bg-transparent text-white border-0   ' name="city" id="" style={{ fontSize: '14px', marginRight: '15px' }} >
                                            <option value="" className='text-dark'>Islamabad</option>
                                            <option value="" className='text-dark'>Lahore</option>
                                            <option value="" className='text-dark'>Karachi</option>
                                            <option value="" className='text-dark'>Faisalabad</option>
                                            <option value="" className='text-dark'>Multan</option>
                                        </select>
                                    </div> */}
                  {/* <div className="price_box d-flex align-items-center">
                    <SellOutlinedIcon
                      style={{ fontSize: "15px" }}
                      className={windowWidth <= 467 ? "d-none" : "shop_icon"}
                    />
                    <select
                      className=" bg-transparent text-white border-0  "
                      name="range"
                      id=""
                      style={{ fontSize: "14px" }}
                      value={selectedValue} // Set the value attribute to the selectedValue state
                      onChange={handleSelectChange}
                    >
                      <option value="5000,20000" className="text-dark">
                        5000 to 20000
                      </option>
                      <option value="20000,50000" className="text-dark">
                        20000 to 50000
                      </option>
                      <option value="50000,100000" className="text-dark">
                        50000 to 100000
                      </option>
                      <option value="100000,200000" className="text-dark">
                        100000 to 200000
                      </option>
                      <option value="200000,400000" className="text-dark">
                        200000 to 400000
                      </option>
                      <option value="400000,700000" className="text-dark">
                        400000 to 700000
                      </option>
                      <option value="700000,120000" className="text-dark">
                        700000 to 120000
                      </option>
                      <option value="190000,210000" className="text-dark">
                        190000 to 210000
                      </option>
                      <option value="300000,500000" className="text-dark">
                        300000 to 500000
                      </option>
                    </select>
                  </div> */}
                  {/* <div className="button_box">
                    <button
                      onClick={() => {
                        seachOnPrice();
                      }}
                      className="bg-info border-0  mx-3 rounded"
                      type="button"
                      style={{ border: "none" }}
                    >
                      <SearchIcon
                        style={{ fontSize: "20px", color: "white" }}
                      />
                    </button>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center img-header  ">
            <img src={img} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
