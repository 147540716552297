import React, { useEffect, useState } from "react";
import Banner from "../../../Component/Banner";
import FeaturedMobile from "../../../Component/FeaturedMobile";
// import BrowseUsedMobile from '../../../Component/BrowseUsedMobile'
import LatestVideos from "../../../Component/LatestVideos";
import Testimonial from "../../../Component/Testimonial";
import RecentlyViewed from "../../../Component/RecentlyViewed";
// import PopUp from '../../../Component/PopUp'
import Branding from "../../../Component/Branding";
import { Helmet } from "react-helmet";

const Home: React.FC = () => {
  // const [showPopup, setShowPopup] = useState(false);
  // useEffect(() => {
  //   const hasVisitedBefore = localStorage.getItem('visited');
  //   if (!hasVisitedBefore) {
  //     setShowPopup(false);
  //     localStorage.setItem('visited', 'true');
  //   }
  // }, []);
  return (
    <>
      <Helmet>
        <title>
          Mobile Marketplace: Your Gateway to Quality Mobiles - Ibad Mobile
        </title>
        <meta
          name="description"
          content="Discover, compare, and find the perfect mobile at Ibad Mobile Marketplace. Connect to quality devices seamlessly for an affordable experience."
        />
        <meta
          name="keywords"
          content="mobile marketplace, latest mobile phones, mobile reviews, customer testimonials, top mobile brands, mobile comparisons, ibad mobile"
        />
        <meta name="google-adsense-account" content="ca-pub-3818311597459709"></meta>
      </Helmet>
      <Banner />
      {/* {showPopup && <PopUp />} */}
      <Branding />
      {/* <BrowseUsedMobile/> */}
      <FeaturedMobile />
      <LatestVideos />
      <Testimonial />
      {/* <RecentlyViewed /> */}
    </>
  );
};

export default Home;
