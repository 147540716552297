import "./mobileDetails.scss";
import React, { useState, useEffect } from "react";
import ShareIcon from "@mui/icons-material/Share";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import StarIcon from "@mui/icons-material/Star";
import ImageGallery from "react-image-gallery";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import loader from "../../../../assets/Screenloader.svg";
import arrow from "../../../../assets/Exchange@2x.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { Helmet } from "react-helmet";
import { Rating } from "react-simple-star-rating";
import slugs from "./../../../../../src/config/slugConfig";
import { MobileData } from "../../NewMobiles/interface";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import InventoryIcon from "@mui/icons-material/Inventory";
import StoreIcon from "@mui/icons-material/Store";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import TextsmsIcon from "@mui/icons-material/Textsms";
import Male from "../../../../assets/male_profile_placeholder.png";

const MobileDetails: React.FC = () => {
  const { id, modelName } = useParams<{ id: string; modelName: string }>();
  const [documents, setDocuments] = useState<MobileData[]>([]);
  const [selectedStorage, setSelectedStorage] = useState<number | 0>(0);
  const [isLoading, setIsLoading] = useState(true);
  const country = useSelector((state: RootState) => state.country.country); // Access mobile data from Redux store
  const currency = useSelector((state: RootState) => state.country.currency); // Access mobile data from Redux store
  const mobileData = useSelector((state: RootState) => state.mobiles.mobiles); // Access mobile data from Redux store
  const mobileDetail = mobileData.find((mobile) => mobile.id === id); // Filter the specific mobile based on id
  const handleRating = (rate: number) => {};
  const onPointerEnter = () => {};
  const onPointerLeave = () => {};
  const onPointerMove = (value: number, index: number) => {};
  // const onPointerEnter = () => console.log("Enter");
  // const onPointerLeave = () => console.log("Leave");
  // const onPointerMove = (value: number, index: number) =>
  //   console.log(value, index);

  useEffect(() => {
    if (mobileDetail) {
      setDocuments([mobileDetail]);
      setIsLoading(false);
    }
  }, [mobileDetail]);

  const renderSEO = () => {
    if (!mobileDetail) return null;

    const title = `${mobileDetail.brandName} ${mobileDetail.modelName} - Detailed Specifications and Prices | Ibad Mobiles`;
    const description = `Get detailed information on ${mobileDetail.brandName} ${mobileDetail.modelName}, including specifications, features, and prices. Compare and find the best deals on Ibad Mobiles.`;
    const keywords = `${mobileDetail.brandName}, ${mobileDetail.modelName}, specifications, features, prices, mobile phones, smartphones`;

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta
          name="google-adsense-account"
          content="ca-pub-3818311597459709"
        ></meta>
      </Helmet>
    );
  };

  // const GetMobileSpecificationData = () => {
  //   try {
  //     const mobileData = [];

  //     if (mobileDetail) {
  //       const data = JSON.parse(JSON.stringify(mobileDetail)); // Clone the mobileDetail to avoid mutating the original data

  //       if (typeof data.colors === "string") {
  //         data.colors = data.colors.split(",");
  //       }

  //       mobileData.push(data);
  //     }

  //     setDocuments(mobileData); // Update your component state
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error("Error processing mobile data: ", error);
  //     setIsLoading(false); // Ensure loading state is updated in case of error
  //   }
  // };

  // useEffect(() => {
  //   setIsLoading(true);
  //   GetMobileSpecificationData();
  // }, [id]); // Trigger the fetch when the `id` parameter changes.

  const handleStorageClick = (index: number) => {
    setSelectedStorage(index);
    // GetMobileSpecificationData();
  };
  const getRating = (mData: any) => {
    const Added =
      mData.audioRating / 200 +
      mData.batteryRating / 200 +
      mData.bluetoothRating / 200 +
      mData.chargingRating / 200 +
      mData.chipsetRating / 200 +
      mData.cpuRating / 200 +
      mData.displayTypeRating / 200 +
      mData.frontCameraRating / 200 +
      mData.gpuRating / 200 +
      mData.internalStorageRating / 200 +
      mData.mainCameraFeaturesRating / 200 +
      mData.mainCameraRating / 200 +
      mData.networkRating / 200 +
      mData.priceRating / 200 +
      mData.radioRating / 200 +
      mData.resolutionRating / 200 +
      mData.sensorsRating / 200 +
      mData.simRating / 200 +
      mData.sizeRating / 200 +
      mData.usbRating / 200 +
      mData.weightRating / 200 +
      mData.wifiTechnologyRating / 200;
    const result = Added / 22;
    return result < 2
      ? 3
      : result < 3 && result > 2
      ? 4
      : result > 3 && result < 4
      ? 4
      : 4.5;
  };

  const FindLinkOfMobile = () => {
    const model = mobileDetail.modelName;
    const myMobile = mobileData.find((mobile) => mobile.modelName === model);

    return `/mobile-details/${myMobile.id}/${myMobile.modelName}`;
  };
  const copyToClipboard = () => {
    const url = window.location.href; // Get the current URL
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert("URL copied to clipboard!"); // You can customize this alert as needed
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <>
      {!isLoading && mobileDetail ? (
        <>
          {renderSEO()}
          {documents.map((items, i) => {
            return (
              <div className="container mb-5" key={i}>
                <div className="row mb-3 mt-5">
                  <div className="col">
                    <nav aria-label="breadcrumb ">
                      <ol className="breadcrumb mb-0">
                        <li
                          className="breadcrumb-item"
                          style={{ fontSize: "12px", fontWeight: "300" }}
                        >
                          <Link className="text-dark" to="/">
                            Home
                          </Link>
                        </li>
                        <li
                          className="breadcrumb-item "
                          style={{ fontSize: "12px", fontWeight: "300" }}
                        >
                          <Link
                            to={`/${slugs.used_mobiles}?reset=reset`}
                            className="text-dark d-inline"
                          >
                            Used Mobiles
                          </Link>
                        </li>
                        <li
                          className="breadcrumb-item active text-info"
                          aria-current="page"
                          style={{ fontSize: "12px", fontWeight: "400" }}
                        >
                          {items.modelName}
                        </li>
                      </ol>
                    </nav>
                    <h3
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "800",
                        fontSize: "24px",
                      }}
                    >
                      {items.brandName} Phones Details in {country}
                    </h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col col-md-6 ">
                    <div className="row">
                      <div className="col ">
                        <ImageGallery
                          items={items.imageUrls.map((url) => ({
                            original: url,
                            thumbnail: url,
                          }))}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col col-md-6 ">
                    <div
                      className="header d-flex flex-column flex-sm-row align-items-center justify-content-sm-between py-2 px-4"
                      style={{ marginBottom: "10px" }}
                    >
                      <p style={{ margin: "0px" }}>AD ID 1088933343</p>
                      <a
                        href="#"
                        style={{
                          color: "black",
                          fontWeight: "500",
                          textDecoration: "underline",
                        }}
                      >
                        Report this ad
                      </a>
                    </div>

                    <div
                      style={{
                        backgroundColor: "#f2f2f2",
                        padding: "10px 16px",
                        borderRadius: "16px",
                      }}
                    >
                      <div className="header d-flex flex-column flex-sm-row align-items-center justify-content-sm-between py-2">
                        <h3 style={{ margin: "0px" }}>{items.modelName}</h3>
                        <div style={{ display: "flex", gap: "8px" }}>
                          <button
                            className="btn"
                            style={{ backgroundColor: "#F4EEFA" }}
                            onClick={copyToClipboard}
                          >
                            <ShareIcon
                              style={{ fontSize: "22px" }}
                              className="text-info"
                            />
                          </button>
                          <button
                            className="btn"
                            style={{ backgroundColor: "#F4EEFA" }}
                          >
                            <FavoriteBorderIcon
                              style={{ fontSize: "22px" }}
                              className="text-info"
                            />
                          </button>
                        </div>
                      </div>
                      <div
                        className="header d-flex flex-column flex-sm-row align-items-center justify-content-sm-between py-2"
                        style={{ marginBottom: "10px" }}
                      >
                        <p style={{ margin: "0px" }}>
                          <LocationOnOutlinedIcon
                            style={{ fontSize: "22px", marginLeft: "-4px" }}
                          />
                          <span style={{ paddingLeft: "4px" }}>
                            Bahria Town Phase 7, Rawalpindi
                          </span>
                        </p>
                        <div>
                          <CircleIcon
                            style={{
                              fontSize: "6px",
                              color: "#A9A9A9",
                              marginLeft: "6px",
                            }}
                          />{" "}
                          <span style={{ color: "#A9A9A9" }}>33 mins ago</span>
                        </div>
                      </div>
                      <div style={{ cursor: "pointer" }}>
                        <span style={{ fontWeight: "500" }}>Price</span> <br />
                        <h3>
                          {currency || "$"}{" "}
                          {items.variants[selectedStorage]?.priceInCurrency}
                        </h3>
                      </div>

                      <div
                        className="d-flex  flex-wrap"
                        style={{ columnGap: "20px" }}
                      >
                        <p>
                          Brand: &ensp;
                          <span style={{ color: "#7b2cbf" }}>
                            {items.brandName}
                          </span>
                        </p>
                        <p>
                          Variant: &ensp;
                          <span style={{ color: "#7b2cbf" }}>8/512 GB</span>
                        </p>
                        <p>
                          Is Deliverable: &ensp;
                          <span style={{ color: "#7b2cbf" }}>No</span>
                        </p>
                        {/* <p>
                          Price: &ensp;
                          <span style={{ color: "#7b2cbf" }}>394,999</span>
                        </p> */}
                        <p>
                          Condition: &ensp;
                          <span style={{ color: "#7b2cbf" }}>Used</span>
                        </p>
                      </div>
                    </div>

                    {/* <h5 className="mt-4 mb-3">Colors</h5>
                    <div className="row ">
                      <div className="col d-flex ">
                        {items.colors && Array.isArray(items.colors) ? (
                          items.colors.map((color, index) => (
                            <div
                              key={index}
                              style={{
                                height: "32px",
                                width: "32px",
                                backgroundColor: color,
                                borderRadius: "100%",
                                border: "1px solid rgba(0,0,0,0.4)",
                              }}
                              className="mx-2"
                            ></div>
                          ))
                        ) : (
                          <div>No colors available</div>
                        )}
                      </div>
                    </div> */}

                    <div
                      style={{
                        backgroundColor: "#f2f2f2",
                        padding: "10px 16px",
                        borderRadius: "16px",
                        marginTop: "20px",
                      }}
                    >
                      <div
                        className="header d-flex flex-column flex-sm-row align-items-center justify-content-sm-between py-2"
                        style={{ marginBottom: "10px" }}
                      >
                        <p style={{ margin: "0px", fontWeight: "500" }}>
                          Product Description
                        </p>
                        <div className="d-flex align-items-center">
                          <InventoryIcon
                            style={{
                              fontSize: "16px",
                              color: "#7b2cbf",
                              marginRight: "8px",
                            }}
                          />
                          <Link
                            to={FindLinkOfMobile()}
                            style={{
                              color: "#7b2cbf",
                              fontWeight: "500",
                              textDecoration: "underline",
                            }}
                          >
                            Product Details
                          </Link>
                        </div>
                      </div>
                      <p style={{ color: "black" }}>
                        Iphone 14 pro max 128gb 2 month sim working 87% battery
                        health condition 10/10 Deep purple color
                      </p>
                    </div>

                    <div
                      style={{
                        backgroundColor: "#f2f2f2",
                        padding: "10px 16px",
                        borderRadius: "16px",
                        marginTop: "20px",
                      }}
                    >
                      <div
                        className="header d-flex flex-column flex-sm-row align-items-center justify-content-sm-between py-2"
                        style={{ marginBottom: "10px" }}
                      >
                        <p style={{ margin: "0px", fontWeight: "500" }}>
                          Seller Details
                        </p>
                        <div className="d-flex align-items-center">
                          <StoreIcon
                            style={{
                              fontSize: "16px",
                              color: "#7b2cbf",
                              marginRight: "8px",
                            }}
                          />
                          <a
                            href="#"
                            style={{
                              color: "#7b2cbf",
                              fontWeight: "500",
                              textDecoration: "underline",
                            }}
                          >
                            See Seller Profile
                          </a>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center gap-3">
                          <img
                            src={Male}
                            alt=""
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "100%",
                            }}
                          />

                          <div>
                            <p style={{ margin: "0px", fontWeight: "500" }}>
                              Seller Details
                            </p>
                            <p style={{ margin: "0px" }}>Seller Details</p>
                          </div>
                        </div>

                        <div
                          className="gap-2 d-flex align-items-center mt-2"
                          style={{ maxWidth: "200px" }}
                        >
                          <button
                            className="btn btn-sm btn-info"
                            style={{ borderRadius: "7px" }}
                          >
                            <LocalPhoneOutlinedIcon
                              style={{ fontSize: "18px" }}
                            />{" "}
                            <span style={{ fontSize: "12px" }}>
                              Contact Now
                            </span>
                          </button>
                          <button
                            className="btn btn-sm btn-info"
                            style={{ borderRadius: "7px" }}
                          >
                            <TextsmsIcon style={{ fontSize: "18px" }} />{" "}
                            <span style={{ fontSize: "12px" }}>Chat</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <h5 className="mt-4">Storage Capacity</h5>
                    <div>
                      <button
                        style={{
                          height: "40px",
                          width: "110px",
                          borderRadius: "10px",
                          border: "2px solid #7b2cbf",
                          marginRight: "15px",
                          backgroundColor: "#f8f9fa",
                          color: "#7b2cbf",
                        }}
                      >
                        ? / ? GB
                      </button>
                      {items.variants.map((variant, index) => (
                        <button
                          key={index}
                          style={{
                            height: "40px",
                            width: "110px",
                            borderRadius: "10px",
                            border:
                              selectedStorage === index
                                ? "2px solid #7b2cbf"
                                : "none",
                            marginRight: "15px",
                            backgroundColor:
                              selectedStorage === index ? "#f8f9fa" : "white",
                            color:
                              selectedStorage === index ? "#7b2cbf" : "#7b2cbf",
                          }}
                          onClick={() => handleStorageClick(index)} // Bind the click event here
                        >
                          {variant.ram}/
                          {variant.internalStorage === 1000
                            ? 1024
                            : variant.internalStorage}{" "}
                          GB
                        </button>
                      ))}
                    </div> */}
                  </div>
                </div>
                {/* <div className="row mt-5 mb-3">
                  <div className="col my-3 d-flex justify-content-center ">
                    <h2
                      className="mx-3"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        borderBottomColor: "#9D4EDD",
                        borderBottomWidth: "3px",
                        borderBottomStyle: "solid",
                      }}
                    >
                      Specifications
                    </h2>
                    <h2
                      className="mx-3"
                      style={{ fontSize: "16px", fontWeight: "300" }}
                    >
                      Reviews
                    </h2>
                    <h2
                      className="mx-3"
                      style={{ fontSize: "16px", fontWeight: "300" }}
                    >
                      FAQ's
                    </h2>
                  </div>
                </div> */}
                {/* <div className="row">
                  <div className="col my-3 text-center">
                    <h2 style={{ fontSize: "24px", fontWeight: "700" }}>
                      Specifications
                    </h2>
                  </div>
                </div>
                <div
                  className="box"
                  style={{ maxWidth: "826px", margin: "0 auto" }}
                >
                  <div className="row bg-secondary">
                    <div className="col  ">
                      <h4
                        className="rounded"
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          paddingLeft: "30px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          margin: "20px 5px",
                          backgroundColor: "#FFFFFF",
                        }}
                      >
                        General Features
                      </h4>
                      <div
                        className="d-flex flex-column justify-content-around mx-5"
                        style={{ color: "#858586" }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Release Date
                          </span>
                          <span className="text-prop-value text-end">
                            22-August-2023
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            SIM Support
                          </span>
                          <span className="text-prop-value text-end">
                            {items.sim}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Phone Dimensions
                          </span>
                          <span className="text-prop-value text-end">
                            {items.dimensions}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Phone Weight
                          </span>
                          <span className="text-prop-value text-end">
                            {items.weight}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Operating System
                          </span>
                          <span className="text-prop-value text-end">
                            {items.os}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row bg-secondary">
                    <div className="col  ">
                      <h4
                        className="rounded"
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          paddingLeft: "30px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          margin: "20px 5px",
                          backgroundColor: "#FFFFFF",
                        }}
                      >
                        Display
                      </h4>
                      <div
                        className="d-flex flex-column justify-content-around mx-5"
                        style={{ color: "#858586" }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Screen Size
                          </span>
                          <span className="text-prop-value text-end">
                            {items.size}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Screen Resolution
                          </span>
                          <span className="text-prop-value text-end">
                            {items.resolution}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Screen Type
                          </span>
                          <span className="text-prop-value text-end">
                            {items.displayType}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Screen Protection
                          </span>
                          <span className="text-prop-value text-end">
                            Corning Gorilla Glass 5
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row bg-secondary">
                    <div className="col  ">
                      <h4
                        className="rounded"
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          paddingLeft: "30px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          margin: "20px 5px",
                          backgroundColor: "#FFFFFF",
                        }}
                      >
                        Memory
                      </h4>
                      <div
                        className="d-flex flex-column justify-content-around mx-5"
                        style={{ color: "#858586" }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Internal Memory
                          </span>
                          <span className="text-prop-value text-end">
                            {items.variants[0].internalStorage === 1000
                              ? 1024
                              : items.variants[0].internalStorage}{" "}
                            GB
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            RAM
                          </span>
                          <span className="text-prop-value text-end">
                            {items.variants[0].ram} GB
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Card Slot
                          </span>
                          <span className="text-prop-value text-end">
                            {items.cardSlot == "true" ? "Yes" : "No"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row bg-secondary">
                    <div className="col  ">
                      <h4
                        className="rounded"
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          paddingLeft: "30px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          margin: "20px 5px",
                          backgroundColor: "#FFFFFF",
                        }}
                      >
                        Performance
                      </h4>
                      <div
                        className="d-flex flex-column justify-content-around mx-5"
                        style={{ color: "#858586" }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            CPU
                          </span>
                          <span className="text-prop-value text-end">
                            {items.cpu}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Chipset
                          </span>
                          <span className="text-prop-value text-end">
                            {items.chipset}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            GPU
                          </span>
                          <span className="text-prop-value text-end">
                            {items.gpu}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row bg-secondary">
                    <div className="col  ">
                      <h4
                        className="rounded"
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          paddingLeft: "30px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          margin: "20px 5px",
                          backgroundColor: "#FFFFFF",
                        }}
                      >
                        Battery
                      </h4>
                      <div
                        className="d-flex flex-column justify-content-around mx-5"
                        style={{ color: "#858586" }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Battery
                          </span>
                          <span className="text-prop-value text-end">
                            {items.battery}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Charging
                          </span>
                          <span className="text-prop-value text-end">
                            {items.charging}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row bg-secondary">
                    <div className="col  ">
                      <h4
                        className="rounded"
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          paddingLeft: "30px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          margin: "20px 5px",
                          backgroundColor: "#FFFFFF",
                        }}
                      >
                        Camera
                      </h4>
                      <div
                        className="d-flex flex-column justify-content-around mx-5"
                        style={{ color: "#858586" }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Front Camera
                          </span>
                          <span className="text-prop-value text-end">
                            {items.frontCamera}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Back Flash Light
                          </span>
                          <span className="text-prop-value text-end">True</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Back Camera
                          </span>
                          <span className="text-prop-value text-end">
                            {items.mainCamera}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Back Camera Features
                          </span>
                          <span className="text-prop-value text-end">
                            {items.mainCameraFeatures}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row bg-secondary">
                    <div className="col  ">
                      <h4
                        className="rounded"
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          paddingLeft: "30px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          margin: "20px 5px",
                          backgroundColor: "#FFFFFF",
                        }}
                      >
                        Connectivity
                      </h4>
                      <div
                        className="d-flex flex-column justify-content-around mx-5"
                        style={{ color: "#858586" }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Bluetooth
                          </span>
                          <span className="text-prop-value text-end">
                            {items.bluetooth}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Network
                          </span>
                          <span className="text-prop-value text-end">
                            {items.network}
                          </span>
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            Radio
                          </span>
                          <span className="text-prop-value text-end">
                            {items.radio}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            WiFi
                          </span>
                          <span className="text-prop-value text-end">
                            {items.wifiTechnology}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span
                            className="text-prop-key my-1"
                            style={{ fontWeight: "500" }}
                          >
                            NFC
                          </span>
                          <span className="text-prop-value text-end">
                            {items.nfc}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            );
          })}
        </>
      ) : (
        <div className="col">
          <div className="loading d-flex justify-content-center ">
            <img src={loader} alt="" style={{ mixBlendMode: "multiply" }} />
          </div>
        </div>
      )}
    </>
  );
};
export default MobileDetails;
